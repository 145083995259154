import React, { useState, useRef } from "react";
import { Button, Modal } from "antd";
import Draggable from "react-draggable";
import { CloseOutlined } from "@ant-design/icons";
export const ModelTabComponent = (props) => {
  const { visible, confirmLoading,handleCancel } = props;
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef();  

  const onStart = (_, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current.getBoundingClientRect();
    if (!targetRect) {
      return;
    }

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  const width = props.isWidth ? { width: "8.567in" } : {};
  return (
    <Modal
      forceRender
      getContainer={false}
      {...width}
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => { }}
          onBlur={() => { }}
        // end
        >
          {props.title}
        </div>
      }
      visible={visible}
      // onOk={props.handleOk}
      // onCancel={handleCancel}
      closeIcon={<CloseOutlined onClick={handleCancel} />}
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء
        </Button>,
        <Button
          htmlType="submit"
          key="submit"
          type="primary"
          loading={confirmLoading}
          onClick={props.handleOk}>
          تأكيد
        </Button>,
      ]}
      confirmLoading={confirmLoading}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}>
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}>
      {props.content}
    </Modal>
  );
};
