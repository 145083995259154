import { Form, Input, Button, Upload, message, Select } from "antd";
import { useState, useEffect } from "react";
import { LoadingSpin } from "../../components/UI/LoadingSpin";
import { ModelTabComponent } from "../../components/UI/modelTab/Index";
import { UserInfoStore } from "../../store/UserInfo";
import { CreateOrUpdateUsers } from "../../api/UserInfoApi/Index";
const AddNewUserInfo = ({ setVisible, visible }) => {
  const [form] = Form.useForm();

  const { record, addUserInfo, setNewadddUs, newadddUs } = UserInfoStore();
  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    setVisible(false);
  };
  const handleOk = (e) => {
    form
      .validateFields()
      .then((val) => {
        onFinish(val);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    form.resetFields();
    if (addUserInfo === false) {
      initialValues();
    }
  }, [visible]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      if (values.password !== values.confirmPassword) {
        message.error("الرمزين غير متطابقين");
        setLoading(false);
        return;
      }
      const data = await CreateOrUpdateUsers(values, record ? record.id : "");
      setNewadddUs(!newadddUs);

      form.resetFields();
      message.success(
        record ? "تمت عملية التعديل بنجاح" : "تمت عملية الاضافة  بنجاح"
      );
      handleCancel();
    } catch (error) {
      message.error(" لم يتم الاضافة ");
    } finally {
      setLoading(false);
    }
  };
  const initialValues = () => {
    form.setFieldsValue({
      username: record.username,
      //password: record.password,
      roll: record.roll,
    });
  };
  const AddUser = () => {
    return (
      <Form onFinish={onFinish} form={form} layout="horizontal">
        <div className="lg:block sm:block md:block xl:block pr-5 ml-5 items-center">
          <Form.Item
            className="lg:w-full  xl:w-full animate__animated animate__fadeInLeft"
            name="username"
            label=" الاسم الكامل"
            rules={[{ required: true, message: "   يجب ادخال الاسم " }]}
          >
            <Input
              autoComplete="off"
              placeholder=" اسم المستخدم الكامل "
              allowClear={true}
            />
          </Form.Item>

          <Form.Item
            className="lg:w-full  xl:w-full animate__animated animate__fadeInLeft"
            name="password"
            label=" الرمز"
            rules={[{ required: true, message: "   يجب ادخال الرمز السري " }]}
          >
            <Input.Password
              autoComplete="off"
              placeholder=" ادخل الرمز السري"
            />
          </Form.Item>
          <Form.Item
            className="lg:w-full  xl:w-full animate__animated animate__fadeInLeft"
            name="confirmPassword"
            label="تأكيد الرمز"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "يرجى تأكيد الرمز السري",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("الرمزين غير متطابقين"));
                },
              }),
            ]}
          >
            <Input
              autoComplete="off"
              placeholder="أعد إدخال الرمز السري"
              type="password"
            />
          </Form.Item>
          <Form.Item
            className="lg:w-full  xl:w-full animate__animated animate__fadeInLeft"
            name="roll"
            label=" نوع المستخدم "
          >
            <Select placeholder="اختر نوع المستخدم">
              <Select.Option value="admin">admin</Select.Option>
              <Select.Option value="user">user</Select.Option>
            </Select>
          </Form.Item>
        </div>
      </Form>
    );
  };
  return (
    <div>
      <ModelTabComponent
        visible={visible}
        setVisible={setVisible}
        title={addUserInfo ? "اضافة مستخدم" : "تعديل المستخدم"}
        content={<AddUser />}
        handleCancel={handleCancel}
        handleOk={handleOk}
        loading={loading}
        isWidth
      />
    </div>
  );
};

export default AddNewUserInfo;
