import TablePreview, { styeColumns } from "../../components/UI/TablePreview";
import { PlusOutlined } from "@ant-design/icons";
import {
  SettingOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { DeleteCompanyById, fetchCompany } from "../../api/CompanyApi/Index";
import {
  Avatar,
  Button,
  Form,
  Input,
  Popconfirm,
  Tooltip,
  message,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import AddNewCompany from "./AddOrEdit";
import { CompanyStore } from "../../store/Company";
import { BASIC_URL } from "../../api";
//import CompanyImage from "./CompanyImage";

function CompanyMade() {
  const [Isloading, setIsloading] = useState(false);
 //const [OpenI, setOpenI] = useState(false);

  const {
    setDataSource,
    dataSource,
    openCo,
    setOpenCo,
    setRecord,
    total,
    setTotal,
    currentPage,
    setCurrentPage,
    paginate,
    setPaginate,
    search,
    setSearch,
    setAddCompany,
    newadddC,
    //setImageLC
  } = CompanyStore();

  const columns = [
    {
      className: styeColumns,
      title: "ت",
      dataIndex: "index",
      //   fixed: 'left',
      ellipsis: true,
      width: 30,
      render: (_, __, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      className: styeColumns,
      title: " صور",
      dataIndex: "ImageUrl",
      //   fixed: 'left',
      ellipsis: true,
      width: 30,
      render: (_, record) => (
        <Avatar
          // onClick={() => {
          //   setImageLC(record);
          //   setOpenI(true);
          // }}
          src={
            <img
              src={`${BASIC_URL}/api/companies/${record.id}/${record.ImageUrl}`}
              alt="avatar"
            />
          }
        />
      ),
    },

    {
      className: styeColumns,
      title: " اسم الشركة",
      dataIndex: "name",
      //   fixed: 'left',
      ellipsis: true,
      width: 100,
    },

    {
      className: styeColumns,
      title: (
        <div className="flex items-center justify-center">
          <SettingOutlined />
        </div>
      ),
      dataIndex: "settings",
      ellipsis: true,
      //fixed: "right",
      width: 32,
      render: (_, record) => (
        <div className="flex items-center justify-center">
          <div className="">
            <Tooltip placement="top" title="تعديل">
              {/* <Popconfirm
                title="هل تريد التعديل ؟"
                okText="نعم "
                cancelText="كلا"
                onConfirm={() => {
                  setAddCompany(false);
                  setRecord(record);
                  setOpenCo(true);
                }}
              > */}
              <EditOutlined
                onClick={() => {
                  setAddCompany(false);
                  setRecord(record);
                  setOpenCo(true);
                }}
                className="cursor-pointer mx-2"
              />
              {/* </Popconfirm> */}
            </Tooltip>
            <Tooltip placement="top" title="حذف">
              <Popconfirm
                title="هل تريد الحذف ؟"
                okText="نعم "
                cancelText="كلا"
                onConfirm={async () => {
                  try {
                    const data = await DeleteCompanyById(record.id);
                    if (data.data.success === true) {
                      loadingData();
                      message.success("تمت عملية الحذف  بنجاح");
                    } else if (data.data.error === "cannot delete") {
                      return message.error("لا يمكن حذف هذا العنصر");
                    } else {
                      return message.warning(
                        "لا يمكن حذف الشركة لأنها قيد الاستخدام في المنتج"
                      );
                    }
                  } catch (error) {
                    return message.warning("يوجد خلل في الاتصال بالخادم");
                  }
                }}
              >
                <DeleteOutlined
                  //style={{ color: "red" }}
                  className="cursor-pointer mx-2"
                />
              </Popconfirm>
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];
  useEffect(() => {
    let isStart = true;
    if (isStart) {
      setIsloading(false);
      loadingData();
    }
    return () => (isStart = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, paginate, search, newadddC]);
  const loadingData = async () => {
    try {
      const data = await fetchCompany({
        //page: currentPage,
        //paginate: paginate,
        search: search,
      });
      setIsloading(true);

      setDataSource(data.data);
      setTotal(data.total);
    } catch (error) {
      console.log("error.message");
    }
    setIsloading(true);
  };

  return (
    <>
      <div className="w-full flex justify-between items-center h-10">
        <Input
          allowClear
          suffix={<SearchOutlined style={{ color: "#808080" }} />}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setCurrentPage(1);
          }}
          placeholder={"بحث"}
          style={{ width: 250 }}
        />
        <Button
          style={{ display: "flex" }}
          icon={<PlusOutlined />}
          onClick={() => {
            setRecord(null);
            setAddCompany(true);
            setOpenCo(true);
          }}
          className="mr-2 flex items-center transition ease-in-out duration-200 transform hover:-translate-y-0 hover:scale-105"
        >
          اضافة
        </Button>
      </div>
      <TablePreview
        columns={columns}
        scrollX={true}
        dataSource={dataSource}
        loading={Isloading}
        total={total}
        paginate={paginate}
        currentPage={currentPage}
        setPaginate={setPaginate}
        setCurrentPage={setCurrentPage}
        widthT={500}
      />
      <AddNewCompany visible={openCo} setVisible={setOpenCo} />
      {/* <CompanyImage visible={OpenI} setVisible={setOpenI} /> */}
    </>
  );
}

export default CompanyMade;
