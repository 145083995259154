import { Avatar } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { TITLE_HEADER } from "../../../constants/constants";
import logo from "../../../assets/images/Group.png";
// import { UnCategorizedStores } from '../../../store/randomStores/unCategorizedStores';
export const AvatarLogo = () => {
  // const { urlImage } = UnCategorizedStores()
  return (
    <Link to="/">
      <Avatar
        alt={TITLE_HEADER}
        src={logo}
        className="flex justify-center cursor-pointer"
        style={{ verticalAlign: "middle", color: "black" }}
        size={50}
        gap={2}
      >
        Logo
      </Avatar>
    </Link>
  );
};
