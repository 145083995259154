import React, { useEffect, useState } from "react";
import { DatePicker, Input, Table, Tooltip } from "antd";
import { fetchCarts } from "../../api/RequestesApi/Index";
import { SearchOutlined } from "@ant-design/icons";
import { SettingOutlined, EditOutlined } from "@ant-design/icons";
import AddNewOrderSelect from "./AddOrEdit";
import { OrderStore } from "../../store/orderstate";
import moment from "moment";
import { styeColumns } from "../../components/UI/TablePreview";
const { RangePicker } = DatePicker;
const InventoryTable = () => {
  const [paginate, setPaginate] = useState(25);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(null);
  const [search, setSearch] = useState("");
  const [EndD, setEndD] = useState("");
  const [StartD, setStartD] = useState("");
  const [Isloading, setIsloading] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const { setRecord } = OrderStore();
  const [DataSource, setDataSource] = useState([]);
  const { newadddO } = OrderStore();

  useEffect(() => {
    let isStart = true;
    if (isStart) {
      setIsloading(false);
      loadingData();
    }
    return () => (isStart = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, paginate, search, newadddO, StartD]);

  const loadingData = async () => {
    try {
      const data = await fetchCarts({
        page: page,
        paginate: paginate,
        search: search,
        start: StartD,
        end: EndD,
      });
      setIsloading(true);
      setDataSource(data.data);
      //console.log(data.data);
      setTotal(data.total);
    } catch (error) {
      console.log("error.message");
    }
    setIsloading(true);
  };

  const expandedRowRender = (record) => {
    //console.log(record);
    const columns = [
      {
        className: styeColumns,
        title: "اسم المنتج",
        dataIndex: "Pname",
        key: "date",
      },
      {
        className: styeColumns,
        title: "الوصف",
        dataIndex: "desc",
        key: "desc",
      },
      {
        className: styeColumns,
        title: "الخصم",
        dataIndex: "discount",
        key: "discount",
      },
      {
        className: styeColumns,
        title: "السعر",
        dataIndex: "price",
        key: "price",
      },
      {
        className: styeColumns,
        title: "السعر بعد الخصم",
        dataIndex: "finalPrice",
        key: "finalPrice",
      },
      {
        className: styeColumns,
        title: "الكمية",
        dataIndex: "qty",
        key: "qty",
      },
      {
        className: styeColumns,
        title: "الفئة",
        dataIndex: "categoryName",
        key: "categoryName",
      },
      {
        className: styeColumns,
        title: "الشركة",
        dataIndex: "companyName",
        key: "companyName",
      },
    ];
    const data = [];

    record.MidCarts.map((item, index) => {
      data.push({
        key: index,
        desc: item.desc,
        discount: item.discount + `%`,
        Pname: item.Pname,
        price: item.price,
        finalPrice: item.finalPrice,
        qty: item.qty,
        categoryName: item.categoryName,
        companyName: item.companyName,
      });
    });

    return (
      <Table
        className="m-5 "
        columns={columns}
        bordered
        dataSource={data}
        pagination={false}
      />
    );
  };

  const columns = [
    {
      className: styeColumns,
      title: "ت",
      dataIndex: "index",
      key: "name",
    },
    {
      className: styeColumns,
      title: "اسم المستخدم",
      dataIndex: "fullName",
      key: "name",
    },
    {
      className: styeColumns,
      title: "رقم المستخدم",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },

    {
      className: styeColumns,
      title: "التاريخ",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      className: styeColumns,
      title: "حالة الطلب  ",
      dataIndex: "OrderState",
      key: "OrderState",
    },
    {
      title: (
        <div className="flex items-center justify-center">
          <SettingOutlined />
        </div>
      ),
      dataIndex: "settings",
      ellipsis: true,
      //fixed: "right",
      render: (_, record) => (
        <div className="flex items-center justify-center">
          <div className="">
            <Tooltip placement="top" title="تعديل">
              <EditOutlined
                onClick={() => {
                  // setAddOeder(false);
                  setRecord(record);
                  setOpenSelect(true);
                }}
                className="cursor-pointer mx-2"
              />
              {/* </Popconfirm> */}
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="w-full flex justify-start items-center h-10 my-2">
        <Input
          className="md:ml-2"
          allowClear
          suffix={<SearchOutlined style={{ color: "#808080" }} />}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setPage(1);
          }}
          placeholder={"بحث"}
          style={{ width: 250 }}
        />

        <RangePicker
          onChange={(value) => {
            if (value && value.length === 2) {
              setStartD(moment(value[0]).format("YYYY-MM-DD"));
              setEndD(moment(value[1]).format("YYYY-MM-DD"));
            } else {
              setStartD("");
              setEndD("");
              //setPage(1);
            }
          }}
        />
      </div>
      <Table
        className=" space-y-10"
        columns={columns}
        pagination={{
          total: total,
          pageSize: paginate,
          current: page,
          showSizeChanger: true,
          pageSizeOptions: [25, 50, 75, 100, 250],
          onChange: (page, pageSize) => {
            setPage(page);
            setPaginate(pageSize);
          },
        }}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ["0"],
        }}
        dataSource={DataSource}
      />
      <AddNewOrderSelect visible={openSelect} setVisible={setOpenSelect} />
    </>
  );
};
export default InventoryTable;
