import { Form, Input, Button, Upload, message, Card, Select } from "antd";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { createOrUpdateProduct } from "../../api/ProductsApi/Index";
import { ProductsStore } from "../../store/ProductsStore";
import { LoadingSpin } from "../../components/UI/LoadingSpin";
import { BASIC_URL } from "../../api";
import {  fetchCompanySelect } from "../../api/CompanyApi/Index";
import {  fetchCategorySelect } from "../../api/CategoryApi/Index";
import { PlusOutlined } from "@ant-design/icons";
import AddNewCategory from "../category/AddOrEdit";
import { CategoryStore } from "../../store/category";
import AddNewCompany from "../CompanyMade/AddOrEdit";
import { CompanyStore } from "../../store/Company";
import { useNavigate } from "react-router-dom";

const { TextArea } = Input;
function AddOrEditProduct() {
  const { record, add } = ProductsStore();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [dataCompany, setDataCompany] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Cheklist, setCheklist] = useState([]);
  const navigate = useNavigate();

  const {
    open,
    setOpen,
    //setRecord,
    setAddCategory,
    newaddd,
  } = CategoryStore();
  const { openCo, setOpenCo, setAddCompany, newadddC } = CompanyStore();
  useEffect(() => {
    loadingDataCompany();
    loadingDataCategory();
  }, [newaddd, newadddC]);

  const loadingDataCompany = async () => {
    setLoading(true);
    try {
      const data = await fetchCompanySelect();
      setLoading(false);
      setDataCompany(data.data);
    } catch (error) {
      console.log("error.message");
    }
    setLoading(false);
  };

  const loadingDataCategory = async () => {
    setLoading(true);
    try {
      const data = await fetchCategorySelect();
      setLoading(false);

      setDataCategory(data.data);
    } catch (error) {
      console.log("error.message");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!add) {
      let Plist = [];
      let Mlist = [];
      record.url.map((item) => {
        fetch(`${BASIC_URL}/api/productes/${record.id}/${item}`)
          .then((res) => res.blob())
          .then((blob) => {
            const aa = new File([blob], `${item}`, {
              type: blob.type,
            });
            aa.url = `${BASIC_URL}/api/productes/${record.id}/${item}`;
            console.log("sa", aa);
            Plist.push(aa);
            // Plist.push({
            //   type:aa.type,
            //   size:aa.size,
            //   // uid: aa.uid,
            //   name: aa.name,
            //   status: "done",
            //   url: `http://localhost:3008/productes/${record.id}/${item}`,
            // });
            Mlist.push(aa);
            let a = {
              // uid: aa.uid,
              type: aa.type,
              size: aa.size,
              name: aa.name,
              status: "done",
              url: `${BASIC_URL}/api/productes/${record.id}/${item}`,
            };
            setFileList((prevFileList) => [...prevFileList, aa]);
            console.log(fileList);
            setCheklist(Mlist);
            form.setFieldsValue({
              url: Mlist,
            });
          });
      });

      initialValues();
    }
  }, []);
  const onFinish = async (values) => {
    //console.log("uuuuuuu", values);
    //let p = values.url;
    let list = [];
    if (add) {
      values.url.fileList.map((item) => list.push(item.originFileObj));
      //console.log(list);
    } else {
      if (values.url.length === Cheklist.length) {
        list = values.url;
        //console.log("done");
      } else {
        values.url.fileList.map((item) =>
          item.originFileObj !== undefined
            ? list.push(item.originFileObj)
            : list.push(item)
        );
        //console.log("list", list);
      }
    }
    try {
      setLoading(true);
      const data = await createOrUpdateProduct(
        {
          id: uuidv4(),
          Pname: values.Pname,
          categoryId: values.categoryId,
          companyId: values.companyId,
          qty: values.qty,
          price: values.price,
          discount: values.discount,
          desc: values.desc,
          url: values.url,
          list: list,
        },
        record ? record.id : ""
      );
      //console.log(data);
      navigate("/");

      if (data.data.success === true) {
        message.success(
          record ? "تمت عملية التعديل بنجاح" : "تمت عملية الإضافة بنجاح"
        );
        setLoading(false);
        form.resetFields();
        setFileList([]);
      } else {
        message.error("حدث خطأ");
        setLoading(false);
      }
    } catch (error) {
      console.log("Validate Failed:", error);
      message.warning("errorMessageNet");
    }
  };
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const initialValues = () => {
    form.setFieldsValue({
      Pname: record.Pname,
      categoryId: record.category.id,
      companyId: record.company.id,
      qty: record.qty,
      price: record.price,
      discount: record.discount,
      desc: record.desc,
    });
  };

  if (loading) return <LoadingSpin />;
  return (
    <div className=" p-3">
      <Card bodyStyle={{ margin: 0, padding: 5 }} className="">
        <Form form={form} onFinish={onFinish} layout="vertical">
          <div className="lg:flex sm:block md:block xl:flex   pr-5 ml-5   items-center justify-between">
            <Form.Item
              className="lg:w-1/4  xl:w-1/4     animate__animated animate__fadeInLeft"
              name="Pname"
              label="اسم المنتج"
              rules={[{ required: true, message: "   يجب ادخال اسم المنتج" }]}
            >
              <Input
                autoComplete="off"
                placeholder={"اسم المنتج"}
                allowClear={true}
              />
            </Form.Item>

            <div className="lg:w-1/4  xl:w-1/4 flex  ">
              <Form.Item
                className="lg:w-11/12  xl:w-11/12  animate__animated animate__fadeInLeft"
                name="categoryId"
                label="  نوع المنتج  "
                rules={[{ required: true, message: "   يجب ادخال نوع المنتج" }]}
              >
                <Select
                  autoComplete="off"
                  placeholder={"نوع المنتج"}
                  allowClear={true}
                  showSearch
                  options={
                    dataCategory &&
                    dataCategory.map((item) => ({
                      key: item.id,
                      value: item.id,
                      label: item.name,
                    }))
                  }
                />
              </Form.Item>
              <Button
                //style={{ display: "flex", paddingRight: 5 }}
                icon={<PlusOutlined />}
                onClick={() => {
                  //setRecord(null);
                  setAddCategory(true);
                  setOpen(true);
                }}
                className="animate__animated animate__fadeInRight  mr-2  lg:w-1/4 mt-8 xl:w-1/4 flex items-center transition ease-in-out duration-200 transform hover:-translate-y-0 hover:scale-110"
              ></Button>
            </div>

            <div className="lg:w-1/4  xl:w-1/4 flex  ">
              <Form.Item
                className="lg:w-11/12  xl:w-11/12     animate__animated animate__fadeInLeft"
                name="companyId"
                label="  الشركة المصنعة  "
                // rules={[
                //   { required: true, message: "   يجب ادخال الشركة المصنعة" },
                // ]}
              >
                <Select
                  autoComplete="off"
                  placeholder={"الشركة المصنعة"}
                  showSearch
                  allowClear={true}
                  options={
                    dataCompany &&
                    dataCompany.map((item) => ({
                      key: item.id,
                      value: item.id,
                      label: item.name,
                    }))
                  }
                />
              </Form.Item>
              <Button
                //style={{ display: "flex", paddingRight: 5 }}
                icon={<PlusOutlined />}
                onClick={() => {
                  //setRecord(null);
                  setAddCompany(true);
                  setOpenCo(true);
                }}
                className="animate__animated animate__fadeInRight  mr-2  lg:w-1/4 mt-8 xl:w-1/4 flex items-center transition ease-in-out duration-200 transform hover:-translate-y-0 hover:scale-110"
              ></Button>
            </div>
          </div>

          <div className="lg:flex sm:block md:block xl:flex   pr-5 ml-5   items-center justify-between">
            <Form.Item
              className="lg:w-1/4  xl:w-1/4     animate__animated animate__fadeInLeft"
              name="qty"
              label="  الكمية  "
              rules={[
                { required: true, message: "   يجب ادخال الكمية" },
                {
                  validator: (_, value) =>
                    value > 0
                      ? Promise.resolve()
                      : Promise.reject("ارقام موجبه فقط"),
                },
              ]}
            >
              <Input
                autoComplete="off"
                placeholder={"الكمية"}
                allowClear={true}
              />
            </Form.Item>
            <Form.Item
              className="lg:w-1/4  xl:w-1/4     animate__animated animate__fadeInLeft"
              name="price"
              label="  السعر  "
              rules={[
                { required: true, message: "   يجب ادخال السعر" },
                {
                  validator: (_, value) =>
                    value > 0
                      ? Promise.resolve()
                      : Promise.reject("ارقام موجبه فقط"),
                },
              ]}
            >
              <Input
                // value={form.getFieldValue("price")}
                // onChange={(e) => {
                //   const rawValue = e.target.value;

                //   // Remove non-digit characters and decimal point
                //   const cleanValue = rawValue.replace(/[^\d.]/g, "");

                //   // Ensure there is only one decimal point
                //   const formattedValue = cleanValue.replace(/(\..*)\./g, "$1");
                //   const formattedWithPoints = formattedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                //   console.log(formattedWithPoints);
                //   form.setFieldValue("price", formattedWithPoints);
                // }}
                autoComplete="off"
                placeholder={"السعر"}
                allowClear={true}
              />
            </Form.Item>
            <Form.Item
              className="lg:w-1/4  xl:w-1/4     animate__animated animate__fadeInLeft"
              name="discount"
              label="  التخفيض  "
              rules={[
                { required: true, message: "   يجب ادخال التخفيض" },
                {
                  validator: (_, value) =>
                    value >= 0
                      ? Promise.resolve()
                      : Promise.reject("ارقام موجبه فقط"),
                },
              ]}
            >
              <Input
                autoComplete="off"
                placeholder={"التخفيض"}
                allowClear={true}
              />
            </Form.Item>
          </div>
          <div className="lg:flex sm:block md:block xl:flex   pr-5 ml-5   items-center justify-between">
            <Form.Item
              className="lg:w-1/4  xl:w-1/4 animate__animated animate__fadeInLeft"
              name="desc"
              label={"وصف المنتج"}
              //rules={[{ required: true, message: "   يجب ادخال وصف المنتج" }]}
            >
              <TextArea
                placeholder="وصف المنتج"
                autoComplete="off"
                allowClear={true}
                // autoSize={{ minRows: 3, maxRows: 3 }}
              />
            </Form.Item>
            <Form.Item
              className="lg:w-2/4  xl:w-2/4     animate__animated animate__fadeInLeft"
              name="url"
              label=" صورة المنتج "
              rules={[{ required: true, message: "   يجب اختيار صورة المنتج" }]}
            >
              {/* <ImgCrop rotationSlider> */}
              <Upload
                maxCount={10}
                multiple={true}
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                showUploadList={{
                  showRemoveIcon: true,
                  showPreviewIcon: false,
                  showDownloadIcon: false,
                }}
                beforeUpload={() => false} // Disable default upload behavior
                //  autoUpload={false} // Delay the upload
              >
                {fileList && "اضافة صورة"}
              </Upload>
            </Form.Item>
          </div>
          <div className="text-center mt-3">
            <Button className="" htmlType="submit">
              حفظ
            </Button>
          </div>
        </Form>
      </Card>
      <AddNewCategory
        visible={open}
        setVisible={setOpen}
        //Cadd={() => loadingDataCategory()}
      />
      <AddNewCompany
        visible={openCo}
        setVisible={setOpenCo}
        CompanAdd={() => loadingDataCompany()}
      />
    </div>
  );
}

export default AddOrEditProduct;
