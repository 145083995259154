import React from "react";
import { Layout, Avatar, Menu, Dropdown } from "antd";
import "./index.less";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { LogoutOutlined } from "@ant-design/icons";
import { Mobile } from "../../Responsive";
import { useNavigate } from "react-router-dom";
import { AvatarLogo } from "../AvatarLogo";
import { UserOutlined } from "@ant-design/icons";

const { Header } = Layout;
export const Navbar = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();

  const menu = (
    <div className="mt-2">
      <Menu
        items={[
          {
            key: "auth",
            icon: <LogoutOutlined />,
            onClick: async () => {
              sessionStorage.clear();
              navigate("/auth");
            },
            label: "تسجيل الخروج",
          },
        ]}
      ></Menu>
    </div>
  );

  return (
    <Header className="app-header shadow">
      <div className="h-16 flex px-5 items-center justify-between">
        <div
          key="collapsed"
          className="cursor-pointer  transition ease-in-out duration-200 transform hover:-translate-y-0 hover:scale-125 "
        >
          {collapsed ? (
            <AiOutlineMenuFold
              size={30}
              onClick={() => setCollapsed(!collapsed)}
            />
          ) : (
            <AiOutlineMenuUnfold
              size={30}
              onClick={() => setCollapsed(!collapsed)}
            />
          )}
        </div>
        <Mobile>
          <div key="AvatarLogo" className="flex items-center justify-around ">
            <AvatarLogo />
          </div>
        </Mobile>
        <div>
          <div
            key="Notification"
            className="flex items-center justify-between  gap-2"
          >
             <h1 className="mt-2 text-xl font-semibold">
              {sessionStorage.getItem("Uname")}
            </h1>
            {/* <h1 className="mt-2">{userInfo.fullname}</h1> */}
            <Dropdown overlay={menu} trigger={["click"]} placement="bottomLeft">
              <Avatar
               
                className="cursor-pointer  transition ease-in-out duration-200 transform hover:-translate-y-0 hover:scale-125 "
                style={{
                  backgroundColor: "#f56a00",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                icon={<UserOutlined />}
              >
                 U
              </Avatar>
            </Dropdown>
          </div>
        </div>
      </div>
    </Header>
  );
};
