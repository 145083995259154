import { BASIC_URL } from "../index";
export const fetchProducts = async ({ page, paginate, search }) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("token", sessionStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const response = await fetch(
      `${BASIC_URL}/api/product/showall?search=${search}&page=${page}&paginate=${paginate}`,
      requestOptions
    );
    if (!response || response.status >= 400) {
      return {
        data: {
          message: response,
        },
      };
    }
    const data = await response.json();
    if (data.success === false) {
      return {
        data: [],
      };
    }
    let list = [];
    data.details.docs.map((item, index) => [
      list.push({
        key: (page - 1) * paginate + index + 1,
        index: (page - 1) * paginate + index + 1,
        id: item.id,
        url: item.ImageUrl.split(","),
        Pname: item.Pname,
        desc: item.desc,
        price: item.price,
        category: item.Category,
        company: item.Company,
        qty: item.qty,
        discount: item.discount,
        isPopular: item.isPopular,
        // isPopular:item.isPopular === true ? "نعم" : "كلا"
      }),
    ]);
    return {
      data: list,
      total: data.details.total,
    };
  } catch (error) {
    console.log(error.message);
  }
};

export const createOrUpdateProduct = async (info, id) => {
  try {
    const formData = new FormData();
    formData.set("Pname", info.Pname);
    formData.set("categoryId", info.categoryId);
    formData.set("companyId", info.companyId);
    formData.set("qty", info.qty);
    formData.set("price", parseInt(info.price));
    formData.set("discount", info.discount);
    formData.set("desc", info.desc);
    for (let i = 0; i < info.list.length; i++) {
      formData.append("files", info.list[i]);
    }
    var myHeaders = new Headers();
    // myHeaders.append("token", JSON.parse(localStorage.getItem("AuthStore")).state.Token );
    myHeaders.append("token", sessionStorage.getItem("token"));

    var requestOptions = {
      method: !id ? "POST" : "PUT",
      // method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    const url = !id
      ? `${BASIC_URL}/api/product?id=${info.id}`
      : `${BASIC_URL}/api/product?id=${id}`;
    const response = await fetch(url, requestOptions);

    // const response = !id
    //   ? await fetch(`${BASIC_URL}/api/product?id=${info.id}`, requestOptions)
    //   : await fetch(`${BASIC_URL}/api/product?id=${id}`, requestOptions);
    const data = await response.json();
    return {
      data: data,
      loading: true,
      hasErrors: false,
    };
  } catch (error) {
    console.log(error.message);
    return {
      data: {
        message: error.message,
      },
      loading: false,
      hasErrors: true,
    };
  }
};
export const UpdatePublic = async (isPopular, id) => {
  try {
    // const formData = new FormData();
    // formData.set("isPopular", isPopular);
    var myHeaders = new Headers();
    // myHeaders.append("token", JSON.parse(localStorage.getItem("AuthStore")).state.Token );
    myHeaders.append("token", sessionStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({ isPopular });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      `${BASIC_URL}/api/product/isPopular?id=${id}`,
      requestOptions
    );
    const data = await response.json();
    return {
      data: data,
      loading: true,
      hasErrors: false,
    };
  } catch (error) {
    console.log(error.message);
    return {
      data: {
        message: error.message,
      },
      loading: false,
      hasErrors: true,
    };
  }
};

export const UpdateAddQty = async (info, id) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("token", sessionStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(info);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      `${BASIC_URL}/api/product/partial?id=${id}`,
      requestOptions
    );
    const data = await response.json();
    //console.log(data);
    return {
      data: data,
    };
  } catch (error) {
    console.log(error.message);
    return {
      data: {
        message: error.message,
      },
    };
  }
};

export const DeleteProductById = async (id) => {
  //console.log(id);
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("token", JSON.parse(localStorage.getItem("AuthStore")).state.Token );
    myHeaders.append("token", sessionStorage.getItem("token"));

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    const response = await fetch(
      `${BASIC_URL}/api/product?id=${id}`,
      requestOptions
    );

    const data = await response.json();
    return {
      data: data,
    };
  } catch (error) {
    console.log(error.message);
    return {
      data: {
        message: error.message,
      },
    };
  }
};
