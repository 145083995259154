import { Form, Select, message } from "antd";
import { useState, useEffect } from "react";

import { ModelTabComponent } from "../../components/UI/modelTab/Index";
import { OrderStore } from "../../store/orderstate";
import { fetchOrderState } from "../../api/OrderApi/Index";
import { createOrUpdateCarts } from "../../api/RequestesApi/Index";
const AddNewOrderSelect = ({ setVisible, visible }) => {
  const [form] = Form.useForm();

  const { record, addOeder ,newadddO, setNewadddO} = OrderStore();
  const [dataOrder, setDataOrder] = useState([]);

  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    setVisible(false);
  };
  const handleOk = (e) => {
    form
      .validateFields()
      .then((val) => {
        onFinish(val);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    form.resetFields();
    if (addOeder === false) {
      initialValues();
    }
  }, [visible]);

  useEffect(() => {
    loadingDataOrder();
  }, []);
  const loadingDataOrder = async () => {
    setLoading(true);
    try {
      const data = await fetchOrderState();
      setLoading(false);
      setDataOrder(data.data);
    } catch (error) {
      console.log("error.message");
    }
    setLoading(false);
  };

  const onFinish = async (value) => {
    setLoading(true);
    //console.log(record, "recordrecordrecord");
    try {
      const data = await createOrUpdateCarts(value, record.id);
      setNewadddO(!newadddO);
      form.resetFields();
      message.success("تمت عملية التعديل بنجاح");
      handleCancel();
    } catch (error) {
      message.error(" لم يتم الاضافة ");
    } finally {
      setLoading(false);
    }
  };

  const initialValues = () => {
    form.setFieldsValue({
      name: record.name,
    });
  };
  const AddOrderModal = () => {
    return (
      <Form onFinish={onFinish} form={form} layout="vertical">
        <div className="lg:flex sm:block md:block xl:flex pr-5 ml-5 items-center justify-around">
          <Form.Item
            className="lg:w-1/2  xl:w-1/2     animate__animated animate__fadeInLeft"
            name="orderStateId"
            label="اسم الحالة"
          >
            <Select
              autoComplete="off"
              placeholder={" اختر الحالة"}
              allowClear={true}
              showSearch
              options={
                dataOrder &&
                dataOrder.map((item) => ({
                  key: item.id,
                  value: item.id,
                  label: item.name,
                }))
              }
            />
          </Form.Item>
        </div>
      </Form>
    );
  };
  return (
    <div>
      <ModelTabComponent
        visible={visible}
        setVisible={setVisible}
        title={"تعديل الحالة"}
        content={<AddOrderModal />}
        handleCancel={handleCancel}
        loading={loading}
        handleOk={handleOk}
      />
    </div>
  );
};

export default AddNewOrderSelect;
