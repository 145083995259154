import {
  Avatar,
  Button,
  Col,
  Collapse,
  Drawer,
  message,
  Radio,
  Row,
  Spin,
  Upload,
} from "antd";
import React, { useState } from "react";
import { ImMagicWand } from "react-icons/im";
import ColorPicker from "../../../../theme/ColorPicker";

import { CardTypeTheme } from "./cardTypeTheme";
import "./index.less";
import lightVars from "../../../../theme/light.json";
import { themeStore } from "../../../../theme/useThemeStore";
import { CardItemColor } from "./cardItemColor";
import { CloudUploadOutlined } from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { UnCategorizedStores } from "../../../../store/randomStores/unCategorizedStores";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
let colorList = [
  "#F5222D",
  "#FA541C",
  "#FA8C16",
  "#FAAD14",
  "#FADB14",
  "#A0D911",
  "#52C41A",
  "#13C2C2",
  "#1890FF",
  "#2F54EB",
  "#722ED1",
  "#EB2F96",
];
const fontSizeList = [12, 14, 15, 16, 17, 18, 19, 20];
export const ThemeDrawer = () => {
  const { Dragger } = Upload;
  const [upLoading, setUpLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const { urlImage, setUrlImage } = UnCategorizedStores();
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setUrlImage("");
    },
    listType: "picture",
    maxCount: 1,
    beforeUpload: async (file) => {
      // *  upload multi image using the down comment
      // setFileList([...fileList, file]);
      // * upload single image using the down line
      setFileList([file]);
      setUpLoading((upLoading) => !upLoading);
      const base64 = await convertBase64(file);
      setTimeout(() => {
        setUpLoading((upLoading) => !upLoading);
        setUrlImage(base64);
      }, 500);
      return false;
    },
    fileList,
  };
  const [visible, setVisible] = useState(false);
  const { vars, setVars, setThemeName, setFontSize, fontSize } = themeStore();

  const handleColorChange = (varname, color) => {
    if (varname) vars[varname] = color;
    window.less
      .modifyVars(vars)
      .then(() => {
        setVars(vars);
        localStorage.setItem("app-theme", JSON.stringify(vars));
      })
      .catch((error) => {
        message.error(`Failed to update theme`);
      });
  };

  const getColorPicker = (varName, position) => (
    <div key={`${varName.name}-${vars[varName.name]}`}>
      <Row className="color-row m-2">
        <Col xs={4} className="color-palette ">
          <ColorPicker
            type="sketch"
            small
            color={vars[varName.name]}
            position={position || "right"}
            presetColors={colorList}
            onChangeComplete={(color) => handleColorChange(varName.name, color)}
          />
        </Col>
        <Col className="color-name" xs={20}>
          {varName.value}
        </Col>
      </Row>

      {varName.name === "@primary-color" ? (
        <Collapse ghost className="customCollapse">
          <Collapse.Panel header="اختيار اللون" key="1">
            <Radio.Group
              size="large"
              onChange={(e) => {
                handleColorChange(e.target.value.varname, e.target.value.color);
              }}
            >
              <div className="flex justify-evenly  mt-2">
                <CardItemColor
                  Radio={Radio}
                  value={{
                    varname: "@primary-color",
                    color: "#F5222D",
                  }}
                  itemColor={"#F5222D"}
                  itemColor2={"#F5222D"}
                  borderRadius1={"0px 5px 5px 0px"}
                  borderRadius2={"0px 4px 4px 0px"}
                />
                {colorList.map((item, index) =>
                  index > 0 && index < 5 ? (
                    <CardItemColor
                      key={index}
                      Radio={Radio}
                      value={{
                        varname: "@primary-color",
                        color: item,
                      }}
                      itemColor={item}
                      itemColor2={item}
                      borderRadius1={"0px"}
                      borderRadius2={"0px"}
                    />
                  ) : null
                )}

                <CardItemColor
                  Radio={Radio}
                  value={{
                    varname: "@primary-color",
                    color: "#A0D911",
                  }}
                  itemColor={"#A0D911"}
                  itemColor2={"#A0D911"}
                  borderRadius1={"5px 0px 0px 5px"}
                  borderRadius2={"4px 0px 0px 4px"}
                />
              </div>
              <div className="flex justify-evenly w-64 mt-2  ">
                <CardItemColor
                  Radio={Radio}
                  value={{
                    varname: varName.name,
                    color: "#52C41A",
                  }}
                  itemColor={"#52C41A"}
                  itemColor2={"#52C41A"}
                  borderRadius1={"0px 5px 5px 0px"}
                  borderRadius2={"0px 4px 4px 0px"}
                />
                {colorList.map((item, index) =>
                  index > 6 && index < 11 ? (
                    <CardItemColor
                      key={index}
                      Radio={Radio}
                      value={{
                        varname: varName.name,
                        color: item,
                      }}
                      itemColor={item}
                      itemColor2={item}
                      borderRadius1={"0px"}
                      borderRadius2={"0px"}
                    />
                  ) : null
                )}

                <CardItemColor
                  Radio={Radio}
                  value={{
                    varname: varName.name,
                    color: "#EB2F96",
                  }}
                  itemColor={"#EB2F96"}
                  itemColor2={"#EB2F96"}
                  borderRadius1={"5px 0px 0px 5px"}
                  borderRadius2={"4px 0px 0px 4px"}
                />
              </div>
            </Radio.Group>
          </Collapse.Panel>
        </Collapse>
      ) : null}
    </div>
  );
  const resetTheme = () => {
    lightVars["@layout-header-background"] = "#fff";
    let vars;
    vars = lightVars;
    vars = {
      ...vars,
      "@white": "#fff",
      "@black": "#000",
      "@primary-color": "#722ED1",
      "@tooltip-bg": "@primary-color",
      "@picker-basic-cell-disabled-bg": "@layout-header-background",
      "@background-color-light": "@component-background",
      "@popover-background": "@layout-header-background",
    };

    localStorage.setItem("app-theme", JSON.stringify(vars));
    localStorage.setItem("theme-name", "light");
    setThemeName("light");
    setVars(vars);
    window.less.modifyVars(vars).catch((_) => {
      localStorage.clear();
      window.location.reload(false);
    });
  };

  const colorPickerOptions = [
    {
      name: "@primary-color",
      value: "اللون الاساسي",
    },
    // {
    //   name: "@secondary-color",
    //   value: 'اللون الثانوي'
    // },
    {
      name: "@text-color",
      value: "لون النص الاساسي",
    },
    //  {
    //   name: "@text-color-secondary",
    //   value: 'لون النص الثانوي'
    // },
    //  {
    //   name: "@heading-color",
    //   value: 'لون العنوان'
    // },
    {
      name: "@btn-primary-bg",
      value: "اللون الازرار الاساسي",
    },
    //  {
    //   name: "@layout-header-background",
    //   value: 'لون  رأس الخلفية '
    // },
    //  {
    //   name: "@body-background",
    //   value: 'لون الخلفية '
    // },
    //  {
    //   name: "@component-background",
    //   value: 'لون المكونات '
    // },
  ];
  const colorPickers = colorPickerOptions.map((varName, index) =>
    getColorPicker(varName, index > 3 ? "top" : "right")
  );
  const [listRestart, setListRestart] = useState([fontSize]);

  return (
    <div>
      <div
        onTouchEnd={() => setVisible(true)}
        title="right long Click to move "
        className="flex items-center justify-center shadow-md
       fixed bottom-2 left-10 z-50 rounded-full cursor-pointer
        border-collapse border w-10 h-10 iconTheme
        transition ease-in-out duration-200 transform hover:-translate-y-0 hover:scale-125
        "
        onClick={() => setVisible(true)}
      >
        <ImMagicWand size={20} />
      </div>
      <Drawer
        placement="left"
        closable
        onClose={() => setVisible(false)}
        visible={visible}
        title="الالوان والثيم"
        width={300}
      >
        <div className="mb-5 font-bold">ثيم الصفحة</div>
        <CardTypeTheme />
        <div className="my-5 font-bold">الوان الثيم</div>
        {colorPickers}
        <div className="my-5 font-bold">حجم الخط</div>
        <Radio.Group
          defaultValue={fontSize}
          size="large"
          onChange={(e) => {
            let val = e.target.value;
            setListRestart((listRestart) => [...listRestart, e.target.value]);
            localStorage.setItem("fontSize", val);
            setFontSize(parseInt(val));
          }}
        >
          <div className="flex justify-evenly  mt-2">
            {fontSizeList.map((item, index) => (
              <Radio.Button
                key={item + index}
                style={{
                  height: "2rem",
                  width: "2rem",
                }}
                value={item}
              >
                {listRestart.some((val) => val === item) ? (
                  <a
                    onClick={() => {
                      localStorage.setItem("fontSize", item);
                      window.location.reload(false);
                    }}
                    href={`${window.location.href}`}
                  >
                    {item}
                  </a>
                ) : (
                  item
                )}
              </Radio.Button>
            ))}
          </div>
        </Radio.Group>
        {false && (
          <Collapse ghost>
            <Collapse.Panel header="اختيار الشعار النظام" key="logoSystem">
              <div style={{ height: "19rem" }}>
                <ImgCrop rotate modalTitle="تعديل الصورة">
                  <Dragger {...props} showUploadList={false}>
                    <p className="ant-upload-drag-icon ">
                      <CloudUploadOutlined className="dark:text-white" />
                    </p>
                    <p className="text-lg "> ختيار الصورة </p>
                    <Avatar
                      size={{
                        xs: 24,
                        sm: 32,
                        md: 40,
                        lg: 64,
                        xl: 80,
                        xxl: 100,
                      }}
                      src={urlImage}
                      style={{ backgroundColor: "#fff" }}
                    >
                      {upLoading ? <Spin indicator={antIcon} /> : "User"}
                    </Avatar>
                    <p className="ant-upload-hint">
                      افلت الصورة هنا او اختر صورة من جهازك
                    </p>
                  </Dragger>
                </ImgCrop>
              </div>
            </Collapse.Panel>
          </Collapse>
        )}
        <div className="h-10 mt-20" />
        <div className="fixed bottom-6 flex items-center justify-center w-64 ">
          <Button type="primary" onClick={resetTheme} title="اعادة ضبط الالوان">
            <a
              onClick={() => {
                window.location.reload(false);
              }}
              href={`${window.location.href}`}
            >
              اعادة ضبط الالوان
            </a>
          </Button>
        </div>
      </Drawer>
    </div>
  );
};
