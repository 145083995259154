import { create } from "zustand";
import { persist } from "zustand/middleware";

let Auth = (set) => ({
 
  isAuth: null,
  setisAuth: (isAuth) => set({ isAuth }),
  User: null,
  setUser: (User) => set({ User }),
  Token: null,
  setToken: (Token) => set({ Token }),
});
Auth = persist(Auth, { name: "AuthStore" });
export const AuthStore = create(Auth);