import { BASIC_URL } from "../index";
export const Login = async (info) => {
  try {
    var raw = JSON.stringify(info);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "post",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      `${BASIC_URL}/api/admin/login`,
      requestOptions
    );
    if (!response || response.status >= 400) {
      return {
        data: {
          message: response,
        },
      };
    }
    const data = await response.json();
    return {
      data: data,
    };
  } catch (error) {
    console.log(error.message);
  }
};
