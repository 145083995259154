import { BASIC_URL } from "../index";

export const CreateOrUpdateCategory = async (info, id) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", sessionStorage.getItem("token"));

    var raw = JSON.stringify(info);
    var requestOptions = {
      method: !id ? "POST" : "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = !id
      ? await fetch(`${BASIC_URL}/api/category`, requestOptions)
      : await fetch(`${BASIC_URL}/api/category?id=${id}`, requestOptions);
    const data = await response.json();
    return {
      data: data,
      loading: true,
      hasErrors: false,
    };
  } catch (error) {
    console.log(error.message);
    return {
      data: {
        message: error.message,
      },
      loading: false,
      hasErrors: true,
    };
  }
};

export const fetchCategory = async ({ search }) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("token", sessionStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const response = await fetch(
      `${BASIC_URL}/api/category/showallPaginate?search=${search}`,
      requestOptions
    );
    if (!response || response.status >= 400) {
      return {
        data: {
          message: response,
        },
      };
    }
    const data = await response.json();

    return {
      data: data.details.docs,
      success: data.success,
    };
  } catch (error) {
    console.log(error.message);
  }
};

export const fetchCategorySelect = async () => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("token", sessionStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const response = await fetch(
      `${BASIC_URL}/api/category/showall`,
      requestOptions
    );
    if (!response || response.status >= 400) {
      return {
        data: {
          message: response,
        },
      };
    }
    const data = await response.json();

    return {
      data: data.details,
      success: data.success,
    };
  } catch (error) {
    console.log(error.message);
  }
};

export const DeleteCategoryById = async (id) => {
  //console.log(id);
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", sessionStorage.getItem("token"));

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    const response = await fetch(
      `${BASIC_URL}/api/category?id=${id}`,
      requestOptions
    );

    const data = await response.json();
    return {
      data: data,
    };
  } catch (error) {
    console.log(error.message);
    return {
      data: {
        message: error.message,
      },
    };
  }
};
