import create from "zustand";
 
 
export const UnCategorizedStores = create((set) => ({
    Auth : false,
    setAuth : (Auth) => set({Auth}), 
    Location : '/',
    setLocation : (Location) => set({Location}), 
    userRoleManage : null,
    setUserRoleManage : (userRoleManage) => set({userRoleManage}),   
      
      // urlImage:logo, 
      setUrlImage: (urlImage) => set({urlImage}),
      width : window.innerWidth,
      setWidth : (width) => set({width}),  
}))