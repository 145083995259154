import React, { useEffect } from "react";
import { themeStore } from "../../theme/useThemeStore";

export const RouteProtection = ({ children }) => {
  const { themeName, vars, setThemeApplied, refresh } = themeStore();
  useEffect(() => {
    let isStart = true;
    if (isStart) {
      if (themeName === "light") vars["@layout-header-background"] = "#fff";
      // vars['@primary-color'] = '#722ED1'
      window.less
        .modifyVars(vars)
        .then(() => {
          setThemeApplied(true);
        })
        .catch((error) => {
          // message.error(`Failed to update theme`);
          localStorage.clear();
          window.location.reload(false);
        });
    }
    return () => {
      isStart = false;
    };
  }, [themeName, vars, refresh, setThemeApplied]);
  return <>{children}</>;
};
