import { Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { ModelTabComponent } from "../UI/modelTab/Index";
import { ProductsStore } from "../../store/ProductsStore";
import { UpdateAddQty } from "../../api/ProductsApi/Index";

const AddQty = ({ setVisible, visible }) => {
  const { record, add, setNewadddQ, newadddQ } = ProductsStore();
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setVisible(false);
  };
  const [form] = Form.useForm();
  const handleOk = (e) => {
    form
      .validateFields()
      .then((val) => {
        onFinish(val);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const onFinish = async (values) => {
    setLoading(true);
    //console.log(values);
    try {
      const addedQty = parseInt(values.addedqty);

      const data = await UpdateAddQty(
        { ...values, addedqty: addedQty },
        record.id
      );
      setNewadddQ(!newadddQ);

      setVisible(false);
      message.success("تمت عملية اضافة الكمية بنجاح");
    } catch (error) {
      message.error("لم يتم الاضافة");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    form.resetFields();
    if (add === false) {
      initialValues();
    }
  }, [visible]);

  const initialValues = () => {
    form.setFieldsValue({
      Pname: record.Pname,
      qty: record.qty,
    });
  };
  const AddQuantity = () => {
    return (
      <Form onFinish={onFinish} form={form} layout="horizontal">
        <div className="form-horizontal-container">
          <Form.Item
            className="     animate__animated animate__fadeInLeft"
            name="Pname"
            label="اسم المنتج"
          >
            <Input
              autoComplete="off"
              placeholder="اسم المنتج"
              allowClear={true}
              disabled
              value={add.Pname}
            />
          </Form.Item>

          <Form.Item
            className="     animate__animated animate__fadeInLeft"
            name="qty"
            label=" الكمية الحالية"
          >
            <Input
              autoComplete="off"
              placeholder="الكمية الحالية"
              allowClear={true}
              disabled
              value={add.qty}
            />
          </Form.Item>

          <Form.Item
            className=" animate__animated animate__fadeInLeft"
            name="addedqty"
            label=" الكمية المضافة"
            rules={[
              { required: true, message: "   يجب ادخال الكمية" },
              {
                validator: (_, value) =>
                  value > 0
                    ? Promise.resolve()
                    : Promise.reject("ارقام موجبه فقط"),
              },
            ]}
          >
            <Input
              autoComplete="off"
              placeholder=" اضافة الكمية الجديدة"
              allowClear={true}
            />
          </Form.Item>
        </div>
      </Form>
    );
  };
  return (
    <div>
      <ModelTabComponent
        visible={visible}
        setVisible={setVisible}
        title={"تعديل الكمية "}
        content={<AddQuantity />}
        handleCancel={handleCancel}
        loading={loading}
        handleOk={handleOk}
      />
    </div>
  );
};

export default AddQty;
