import React, { useState } from "react";
import { UserOutlined, KeyOutlined } from "@ant-design/icons";
import "../../styles/Auth.css";
import { Button, Card, Form, Input, Layout, message } from "antd";
import { Default } from "../../components/UI/Responsive";
import I1 from "../../assets/images/12.png";
import { LoadingSpin } from "../../components/UI/LoadingSpin";
import { useNavigate } from "react-router-dom";
import { AuthStore } from "../../store/AuthStore/Index";
import { Login } from "../../api/AuthApi/Index";
import { data } from "autoprefixer";
function Auth() {
  const [loading, setLoading] = useState(false);
  const { setUser, setisAuth, setToken } = AuthStore();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const data = await Login({
        username: values.username,
        password: values.password,
      });
      if (data.data.data.message === "Auth successful") {
        message.success("تمت عملية الدخول بنجاح");
        setLoading(false);
        sessionStorage.setItem("isAuth", "AdminIsCompletlyAuth");
        sessionStorage.setItem("token", data.data.data.token);
        sessionStorage.setItem("UserRoll", data.data.data.roll);
        sessionStorage.setItem("Uname", data.data.data.username);
        //console.log(data.data.data);
        navigate("/");
      } else if (data.data.data.message === "Invalid username!") {
        message.error("خطأ في اسم المستخدم ");
        setLoading(false);
      } else if (data.data.message === "Invalid Password!") {
        message.error("خطأ في كلمة المرور ");
        setLoading(false);
      } else {
        message.error("حدث خطأ");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      message.warning("خطأ في اسم المستخدم او كلمة المرور");
      setLoading(false);
      // setConfirmLoading(false);
    }
  };
  if (loading) return <LoadingSpin />;
  return (
    <div
      className="relative overflow-hidden"
      style={{
        height: "100vh ",
      }}
    >
      <div
        className="z-0 w-full fixed top-0 left-0"
        style={{
          height: "calc(100vh - 50vh)",
          background: "linear-gradient(180deg, #D3DFFB 0%, #F3F4FC 100%)",
        }}
      ></div>
      <div
        className="fixed -top-28 w-[660px] h-[606px] rounded-full -left-36 z-0"
        style={{
          background:
            "radial-gradient(201.83% 201.83% at -98.17% -107.83%, #C0B1EE 0%, rgba(192, 177, 238, 0) 100%)",
          filter: "blur(63.5px)",
        }}
      ></div>
      <div
        className="absolute right-32 rounded-full top-24 h-24 w-24"
        style={{
          background: "linear-gradient(180deg, #6AD8FB 0%, #006383 100%)",
          opacity: "0.38",
        }}
      ></div>
      <div
        className="absolute -left-3 rounded-full top-60 h-24 w-24"
        style={{
          background: "linear-gradient(180deg, #6AD8FB 0%, #006383 100%)",
          opacity: "0.38",
        }}
      ></div>
      <div
        className="absolute left-[851px] rounded-full top-[813px] h-24 w-24"
        style={{
          background: "linear-gradient(180deg, #6AD8FB 0%, #006383 100%)",
          opacity: "0.38",
        }}
      ></div>
      <Layout className="w-full h-full ">
        <div className="z-10 flex items-center justify-around w-full h-full ">
          <Default>
            <div className="xl:w-[631px] xl:h-[611px] lg:w-[631px] lg:h-[611px] md:w-[400px] md:h-[400px]">
              <img alt="/" src={I1}></img>
            </div>
          </Default>
          <Card className="h-[571px] w-[370px] md:w-[447px] drop-shadow-md ">
            <div className="flex flex-col justify-center">
              <div className="mt-40">
                <Form
                  name="authPage"
                  // form={form}
                  layout="vertical"
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="username"
                    label={"اسم المستخدم "}
                    rules={[
                      {
                        required: true,
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject(
                              <span style={{ fontSize: "12px" }}>
                                ادخل اسم المستخدم
                              </span>
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined style={{ color: "#28AAD6" }} />}
                      placeholder={"اسم المستخدم"}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label={"كلمة المرور"}
                    rules={[
                      {
                        required: true,
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject(
                              <span style={{ fontSize: "12px" }}>
                                ادخل كلمة المرور
                              </span>
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input.Password
                      prefix={<KeyOutlined style={{ color: "#28AAD6" }} />}
                      placeholder={"كلمة المرور"}
                    />
                  </Form.Item>

                  <Form.Item className="flex items-center justify-center">
                    <Button
                      // loading={loading}
                      htmlType="submit"
                      style={{
                        background:
                          "linear-gradient(95.29deg, #00BAEB 0%, #2293B9 106.52%)",
                        color: "#ffffff",
                      }}
                      className="w-[125px]  mt-10 flex justify-center items-center transition ease-in-out duration-200 transform hover:-translate-y-0 hover:scale-110"
                    >
                      {"تسجيل الدخول"}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Card>
        </div>
      </Layout>
    </div>
  );
}

export default Auth;
