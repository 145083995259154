
import {create} from "zustand";

export const themeStore = create((set) => ({
  refresh: false,
  setRefresh: (refresh) => set({ refresh }),
  themeApplied: false,
  setThemeApplied: (themeApplied) => set({ themeApplied }),
  vars: Object.assign(
    {},
    JSON.parse(localStorage.getItem("app-theme"))),

  setVars: (vars) => set({ vars }),
  themeName: localStorage.getItem("theme-name") || 'light',
  setThemeName: (themeName) => set({ themeName }),
  fontSize: parseInt(localStorage.getItem("fontSize")) || 16,
  setFontSize: (fontSize) => set({ fontSize }),


}))