import lightVars from '../../../../theme/light.json'
import darkVars from '../../../../theme/dark.json'
import { Radio } from 'antd'
import React from 'react'
import { CardItemColor } from './cardItemColor'
import { themeStore } from '../../../../theme/useThemeStore'

export const CardTypeTheme = () => {
    const { setVars, themeName, setThemeName } = themeStore()
    const handleColorChange = e => {
        let vars
        if (e.target.value === 'light') {
            lightVars['@layout-header-background'] = '#fff'
            vars = lightVars
            vars = {
                ...vars,
                '@white': '#fff',
                '@black': '#000',
                '@primary-color': '#722ED1',
                '@tooltip-bg': '@primary-color',
                "@picker-basic-cell-disabled-bg": "@layout-header-background",
                "@background-color-light": "@component-background",
                '@popover-background': '@layout-header-background',
            };

        }
        if (e.target.value === 'light1') {
            vars = lightVars
            vars = {
                ...vars, '@white': '#fff',
                '@black': '#000',
                '@primary-color': '#31E8F6',
                '@body-background': '#ffe',
                '@layout-header-background': '#ffe',
                "@component-background": '#ffe',
                '@modal-header-bg': '@component-background',
                '@modal-content-bg': '@component-background',
                '@table-header-bg': '#fff',
                '@table-row-hover-bg': '#ffe',
                '@drawer-bg': '#ffe',
                '@select-dropdown-bg': '#ffe',
                '@tooltip-bg': '@primary-color',
                "@picker-basic-cell-disabled-bg": "@layout-header-background",
                "@background-color-light": "@component-background",
                '@popover-background': '@layout-header-background',
            };

        }
        if (e.target.value === 'dark') {
            vars = darkVars
            vars = {
                ...vars,
                '@white': '#fff',
                '@black': '#000',
                '@primary-color': '#722ED1',
                '@body-background': '#111',
                '@tooltip-bg': '@primary-color',
                "@picker-basic-cell-disabled-bg": "@layout-header-background",
                "@background-color-light": "@component-background",
            };

        }
        if (e.target.value === 'dark1') {
            vars = darkVars
            vars = {
                ...vars, '@white': '#fff',
                '@black': '#000',
                '@primary-color': '#13C2C2',
                '@tooltip-bg': '@primary-color',
                '@body-background': '#201f24',
                '@layout-header-background': '#2a2930',
                "@component-background": '#201f24',
                '@modal-header-bg': '@component-background',
                '@modal-content-bg': '@component-background',
                '@slider-rail-background-color': '@background-color-base',
                '@table-header-bg': '#2a2930',
                '@table-row-hover-bg': '#2a2930',
                '@drawer-bg': '#2a2930',
                '@select-dropdown-bg': '#2a2930',
                "@picker-basic-cell-disabled-bg": "@layout-header-background",
                "@background-color-light": "@component-background",
                '@popover-background': '@layout-header-background',
            };

        }
        if (e.target.value === 'dark2') {
            vars = darkVars
            vars = {
                ...vars, '@white': '#fff',
                '@black': '#000',
                '@primary-color': '#EB2F96',
                '@body-background': '#10042a',
                '@layout-header-background': '#1a0d38',
                "@component-background": '#10042a',
                '@modal-header-bg': '@component-background',
                '@modal-content-bg': '@component-background',
                '@table-header-bg': '#1a0d38',
                '@table-row-hover-bg': '#1a0d38',
                '@drawer-bg': '#1a0d38',
                '@select-dropdown-bg': '#1a0d38',
                '@tooltip-bg': '@primary-color',
                "@picker-basic-cell-disabled-bg": "@layout-header-background",
                "@background-color-light": "@component-background",
                '@popover-background': '@layout-header-background',
            };

        }
        if (e.target.value === 'dark3') {
            vars = darkVars
            vars = {
                ...vars, '@white': '#fff',
                '@black': '#000',
                '@primary-color': '#13C2C2',
                '@body-background': '#191a2a',
                '@layout-header-background': '#222436',
                "@component-background": '#191a2a',
                '@modal-header-bg': '@component-background',
                '@modal-content-bg': '@component-background',
                '@table-header-bg': '#222436',
                '@table-row-hover-bg': '#222436',
                '@drawer-bg': '#222436',
                '@select-dropdown-bg': '#222436',
                '@tooltip-bg': '@primary-color',
                "@picker-basic-cell-disabled-bg": "@layout-header-background",
                "@background-color-light": "@component-background",
                '@popover-background': '@layout-header-background',
            };

        }

        setThemeName(e.target.value);
        setVars(vars);
        localStorage.setItem("app-theme", JSON.stringify(vars));
        localStorage.setItem("theme-name", e.target.value);
        window.less.modifyVars(vars).catch(_ => {
            localStorage.clear()
            window.location.reload(false);
        });
    }

    return (
        <Radio.Group
            defaultValue={themeName} size="large"
            onChange={handleColorChange}
        >
            <div className='flex justify-evenly w-64 mt-5 '>
                <CardItemColor
                    Radio={Radio}
                    value={'light'}
                    itemColor={'#fff'}
                    itemColor2={'#f0f1f5'}
                    borderRadius1={'0px 5px 5px 0px'}
                    borderRadius2={'0px 4px 4px 0px'}
                />
                <CardItemColor
                    Radio={Radio}
                    value={'light1'}
                    itemColor={'#fff'}
                    itemColor2={'#ffe'}
                    borderRadius1={'0px'}
                    borderRadius2={'0px'}
                />
                <CardItemColor
                    Radio={Radio}
                    value={'dark'}
                    itemColor={'#1f1f1f'}
                    itemColor2={'#000'}
                    borderRadius1={'5px 0px 0px 5px'}
                    borderRadius2={'4px 0px 0px 4px'}
                />
            </div>


            <div className='flex justify-evenly w-64 mt-5 '>

                <CardItemColor
                    Radio={Radio}
                    value={'dark1'}
                    itemColor={'#2a2930'}
                    itemColor2={'#201f24'}
                    borderRadius1={'5px 0px 0px 5px'}
                    borderRadius2={'4px 0px 0px 4px'}
                />


                <CardItemColor
                    Radio={Radio}
                    value={'dark2'}
                    itemColor={'#1a0d38'}
                    itemColor2={'#10042a'}
                    borderRadius1={'0px'}
                    borderRadius2={'0px'}
                />
                <CardItemColor
                    Radio={Radio}
                    value={'dark3'}
                    itemColor={'#222436'}
                    itemColor2={'#191a2a'}
                    borderRadius1={'0px 5px 5px 0px'}
                    borderRadius2={'0px 4px 4px 0px'}
                />
            </div>
        </Radio.Group>
    )
}
