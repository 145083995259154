import { Form, Input, message } from "antd";
import { useState, useEffect } from "react";
import { LoadingSpin } from "../../components/UI/LoadingSpin";
import { ModelTabComponent } from "../../components/UI/modelTab/Index";
import { OrderStore } from "../../store/orderstate";
import { CreateOrUpdateOrderState } from "../../api/OrderApi/Index";
const AddNewOrderPage = ({ setVisible, visible }) => {
  const [form] = Form.useForm();

  const { record, addOeder, newadddO, setNewadddO } = OrderStore();
  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    setVisible(false);
  };
  const handleOk = (e) => {
    form
      .validateFields()
      .then((val) => {
        onFinish(val);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    form.resetFields();
    if (addOeder === false) {
      initialValues();
    }
  }, [visible]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const data = await CreateOrUpdateOrderState(
        values,
        record ? record.id : ""
      );
      setNewadddO(!newadddO);

      form.resetFields();
      message.success(
        record ? "تمت عملية التعديل بنجاح" : "تمت عملية اضافة الحالة بنجاح"
      );
      handleCancel();
    } catch (error) {
      message.error(" لم يتم الاضافة ");
    } finally {
      setLoading(false);
    }
  };
  const initialValues = () => {
    form.setFieldsValue({
      name: record.name,
    });
  };
  const AddOrderModal = () => {
    return (
      <Form onFinish={onFinish} form={form} layout="horizontal">
        <div className="lg:flex sm:block md:block xl:flex pr-5 ml-5 items-center ">
          <Form.Item
            className="lg:w-full  xl:w-full     animate__animated animate__fadeInLeft"
            name="name"
            label="اسم الحالة"
          >
            <Input
              autoComplete="off"
              placeholder="اسم الحالة"
              allowClear={true}
            />
          </Form.Item>
        </div>
      </Form>
    );
  };
  return (
    <div>
      <ModelTabComponent
        visible={visible}
        setVisible={setVisible}
        title={addOeder ? "اضافة الحالة" : "تعديل الحالة"}
        content={<AddOrderModal />}
        handleCancel={handleCancel}
        loading={loading}
        handleOk={handleOk}
      />
    </div>
  );
};

export default AddNewOrderPage;
