import { Form, Input, message } from "antd";
import { useState, useEffect } from "react";
import { CategoryStore } from "../../store/category";
import { LoadingSpin } from "../../components/UI/LoadingSpin";
import { ModelTabComponent } from "../../components/UI/modelTab/Index";
import { CreateOrUpdateCategory } from "../../api/CategoryApi/Index";
const AddNewCategory = ({ setVisible, visible }) => {
  const [form] = Form.useForm();

  const { record, addCategory, newaddd, setNewaddd } = CategoryStore();
  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    setVisible(false);
  };
  const handleOk = (e) => {
    form
      .validateFields()
      .then((val) => {
        onFinish(val);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    form.resetFields();
    if (addCategory === false) {
      initialValues();
    }
  }, [visible]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const data = await CreateOrUpdateCategory(
        values,
        record ? record.id : ""
      );
      setNewaddd(!newaddd);

      form.resetFields();
      message.success(
        record ? "تمت عملية التعديل بنجاح" : "تمت عملية اضافة الفئة بنجاح"
      );
      handleCancel();
      //setVisible(false);
    } catch (error) {
      message.error(" لم يتم الاضافة ");
    } finally {
      setLoading(false);
    }
  };
  const initialValues = () => {
    form.setFieldsValue({
      name: record.name,
    });
  };
  const AddCategory = () => {
    return (
      <Form onFinish={onFinish} form={form} layout="horizontal">
        <div className="lg:flex sm:block md:block xl:flex pr-5 ml-5 ">
          <Form.Item
            className="lg:w-full  xl:w-full     animate__animated animate__fadeInLeft"
            name="name"
            label="اسم الفئة"
          >
            <Input
              autoComplete="off"
              placeholder="اسم الفئة"
              allowClear={true}
            />
          </Form.Item>
        </div>
      </Form>
    );
  };
  return (
    <div>
      <ModelTabComponent
        visible={visible}
        setVisible={setVisible}
        title={addCategory ? "اضافة فئة" : "تعديل الفئة"}
        content={<AddCategory />}
        handleCancel={handleCancel}
        loading={loading}
        handleOk={handleOk}
      />
    </div>
  );
};

export default AddNewCategory;
