import create from "zustand";
export const ProductsStore = create((set) => ({
  add: true,
  setAdd: (add) => set({ add }),
  newadddQ: true,
  setNewadddQ: (newadddQ) => set({ newadddQ }),

  deleteStaff: true,
  setDeleteStaff: (deleteStaff) => set({ deleteStaff }),
  deleteAllStaff: true,
  setDeleteAllStaff: (deleteAllStaff) => set({ deleteAllStaff }),
  record: null,
  setRecord: (record) => set({ record }),
  ImageL: null,
  setImageL: (ImageL) => set({ ImageL }),

  //store Table
  loading: false,
  setLoading: (loading) => set({ loading }),
  columnsData: [],
  setColumnsData: (columnsData) => set({ columnsData }),
  columnsDataInit: [],
  setColumnsDataInit: (columnsDataInit) => set({ columnsDataInit }),
  hasErrors: false,
  setHasErrors: (hasErrors) => set({ hasErrors }),
  total: 1,
  setTotal: (total) => set({ total }),
  currentPage: 1,
  setCurrentPage: (currentPage) => set({ currentPage }),
  paginate: 25,
  setPaginate: (paginate) => set({ paginate }),

  dataSource: [],
  setDataSource: (dataSource) => set({ dataSource }),

  selectedRows: [],
  setSelectedRows: (selectedRows) => set({ selectedRows }),
  selectedRowKeys: [],
  setSelectedRowKeys: (selectedRowKeys) => set({ selectedRowKeys }),

  search: "",
  setSearch: (search) => set({ search }),
  fromHistory: null,
  setFromHistory: (fromHistory) => set({ fromHistory }),

  toHistory: null,
  setToHistory: (toHistory) => set({ toHistory }),
}));
