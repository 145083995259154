import React, { useEffect, useState } from "react";
import { Menu } from "antd";

import { Link, useLocation } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BiSitemap } from "react-icons/bi";
import { TitleSidebar } from "./titleSidebar";
import { Icon } from "@iconify/react";
export const SidebarItem = ({ collapsed }) => {
  let location = useLocation().pathname.split("/")[1];
  const rootSubmenuKeys = [
    "dashboard",
    "roleUserManagement",
    "organizationalStructure",
    "humanResources",
    "attendance",
    "transactionReport",
  ];
  const [openKeys, setOpenKeys] = useState([]);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const [roul, setRoul] = useState(null);
  useEffect(() => {
    if (sessionStorage.getItem("UserRoll") === "admin") {
      setRoul(true);
    } else {
      setRoul(false);
    }
  }, [setRoul]);
  
  return (
    <div>
      <TitleSidebar collapsed={collapsed} />
      <Menu
        mode="inline"
        className="scrollbox finished"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        // defaultSelectedKeys={[`${location}`]}
        selectedKeys={[`${location}`]}
        style={{
          height: "calc(100vh - 64px)",
        }}
        items={[
          {
            key: "product",
            icon: <BiSitemap />,
            label: <Link to={"/"}> {"المنتجات"}</Link>,
          },
          {
            key: "category",
            icon: <Icon icon="iconamoon:category-fill" />,
            label: <Link to={"/category"}> {"الفئات"}</Link>,
          },
          {
            key: "CompanyMade",
            icon: <Icon icon="mdi:company" />,
            label: <Link to={"/CompanyMade"}> {"الشركات المصنعة"}</Link>,
          },
          {
            key: "orderstate",
            icon: <Icon icon="lets-icons:order" />,
            label: <Link to={"/orderstate"}> {" الحالات"}</Link>,
          },

          {
            key: "userPage",
            icon: <Icon icon="fa:users" />,
            label: <Link to={"/userPage"}> {" المستخدمين"}</Link>,
            hidden: !roul,
          },
          {
            key: "Inventory",
            icon: <Icon icon="carbon:intent-request-scale-out" />,
            label: <Link to={"/Inventory"}> {" الطلبات"}</Link>,
          },
        ]}
      />
    </div>
  );
};
