import React, { useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, Divider, Input, Table } from "antd";
import "./index.less";
import PropTypes from "prop-types";
import { Resizable } from "react-resizable";
import { UnCategorizedStores } from "../../../store/randomStores/unCategorizedStores";
import { okText, resetText, searchTitle } from "../../../constants/string";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
const CheckboxGroup = Checkbox.Group;
export const styeColumns = " animate__animated animate__fadeInRight";
const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={parseInt(width)}
      height={0}
      handle={
        <span
          className="react-resizable-handle select-none"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      resizeHandles={["w"]}
      draggableOpts={{
        enableUserSelectHack: false,
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const TablePreview = ({
  widthT,
  rowSelection,
  columns,
  loading,
  total,
  paginate,
  setPaginate,
  setCurrentPage,
  hasErrors,
  dataSource,
  scrollX,
  scrollY,
  expandable,
  ExpandableData,
  currentPage,
  onChange,
  isEditColumn,
  styleTable,
  styleFooter,
  summary,
}) => {
  const [column, setColumn] = useState(columns);
  const { width, setWidth } = UnCategorizedStores();
  useEffect(() => {
    let isStart = true;
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };
    if (isStart) {
      window.addEventListener("resize", updateWindowDimensions);
    }
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [setWidth]);
  let isScroll = () => {
    if (scrollX) {
      if (widthT >= 768) {
        if (widthT > 1500) {
          return widthT;
        } else {
          return 1500;
        }
      } else if (width <= 768) return 1500;
      else return null;
    } else return null;
  };

  const handleResize =
    (index) =>
    (_, { size }) => {
      const newColumn = [...column];
      newColumn[index] = { ...newColumn[index], width: size.width };
      setColumn(newColumn);
    };

  const mergeColumn = column.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  return (
    <Table
      components={{
        header: {
          cell: ResizableTitle,
        },
      }}
      className={`${styleTable} rounded-md mt-2 ${styleFooter} shadow`}
      tableLayout="auto"
      // size='small'
      rowSelection={rowSelection}
      scroll={{
        scrollToFirstRowOnChange: true,
        y: scrollY ? "calc(100vh - 25rem)" : "calc(100vh - 20rem)",
        x: isScroll(),
      }}
      // scroll={{ scrollToFirstRowOnChange: true, x: "100%" }}
      columns={isEditColumn ? columns : mergeColumn}
      loading={!loading}
      pagination={{
        total: total,
        pageSize: paginate,
        defaultPageSize: 25,
        // responsive: true,
        pageSizeOptions: [25, 50, 75, 100, 250],
        showSizeChanger: true,
        current: currentPage,
        defaultCurrent: currentPage,
        onChange: (page, pageSize) => {
          setPaginate(pageSize);
          setCurrentPage(page);
        },
        // size:'default'
        // position: [bottom],
        // showSizeChanger: true,
        // showQuickJumper: true
        // onchange: (e) => {
        //   setPaginate(e.pageSize);
        //   setCurrentPage(e.current);
        // }
      }}
      onChange={onChange}
      // onChange={(e) => {
      //   setPaginate(e.pageSize);
      //   setCurrentPage(e.current);
      // }}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            // console.log(record);
          }, // click row
        };
      }}
      dataSource={!hasErrors ? dataSource : null}
      expandable={{
        expandedRowRender: ExpandableData
          ? (record) => <ExpandableData record={record} />
          : null,
        rowExpandable: expandable,
      }}
      summary={summary}
    />
  );
};
TablePreview.propTypes = {
  widthT: PropTypes.number,
  currentPage: PropTypes.number,
  paginate: PropTypes.number,
  total: PropTypes.number,
  loading: PropTypes.bool,
  scrollY: PropTypes.bool,
  scrollX: PropTypes.bool,
  expandable: PropTypes.func,
  hasErrors: PropTypes.bool,
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  setPaginate: PropTypes.func,
  setCurrentPage: PropTypes.func,
  onChange: PropTypes.func,
  styleTable: PropTypes.string,
  styleFooter: PropTypes.string,
  summary: PropTypes.func,
};

TablePreview.defaultProps = {
  currentPage: 1,
  paginate: 25,
  loading: false,
  scrollY: false,
  scrollX: false,
  expandable: () => false,
  hasErrors: false,
  onChange: () => {},
  styleTable: "TablePreview",
  styleFooter: "footerTable",
  summary: () => {},
};

export default TablePreview;

export const getColumnSearchRenegeDateProps = (_) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdownSearchRenegeDate
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
    />
  ),
});

const FilterDropdownSearchRenegeDate = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  return (
    <div style={{ padding: 0 }}>
      <div className="flex flex-col gap-2">
        <DatePicker.RangePicker
          style={{
            margin: 10,
          }}
          {...(selectedKeys.length > 0
            ? {
                value: [moment(selectedKeys[0]), moment(selectedKeys[1])],
              }
            : null)}
          onChange={(_, val) => {
            setSelectedKeys(_ ? val : []);
          }}
        />
        <Divider style={{ padding: 0, margin: 0 }} />
        <div className="flex  gap-2 p-2 justify-between">
          <Button
            disabled={selectedKeys.length === 0}
            type="link"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              clearFilters && clearFilters();
            }}
          >
            {resetText}
          </Button>
          <Button
            type="primary"
            // icon={<SearchOutlined />}
            size="small"
            onClick={() => {
              confirm();
            }}
          >
            {okText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const getColumnSearchFilterProps = (_, loadingData) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdownSearchFilter
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
      loadingData={loadingData}
    />
  ),
});
const FilterDropdownSearchFilter = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  loadingData,
}) => {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    let isStart = true;
    if (isStart) {
      const load = async () => {
        setDataSource(await loadingData(""));
      };
      load();
    }
    return () => (isStart = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <div className="p-2">
        <Input
          suffix={<SearchOutlined style={{ color: "#808080" }} />}
          onChange={async (e) =>
            setDataSource(await loadingData(e.target.value))
          }
        />
      </div>

      <Divider style={{ padding: 0, margin: 0 }} />
      <CheckboxGroup
        className={`${dataSource.length >= 10 ? "h-72" : ""} overflow-y-auto `}
        value={selectedKeys}
        onChange={(checkedValue) => {
          setSelectedKeys(checkedValue ? checkedValue : []);
        }}
      >
        <div className="flex flex-col gap-0 w-full">
          {dataSource.map((item, index) => (
            <div
              className={`w-full p-1 ${
                selectedKeys.includes(item.name) ? "primaryColorBackground" : ""
              }`}
              key={index}
            >
              <Checkbox className="w-full" value={item.name}>
                {item.name}
              </Checkbox>
            </div>
          ))}
        </div>
      </CheckboxGroup>
      <Divider style={{ padding: 0, margin: 0 }} />
      <div className="flex  gap-2 p-2 justify-between">
        <Button
          disabled={selectedKeys.length === 0}
          type="link"
          size="small"
          onClick={async () => {
            setSelectedKeys([]);
            clearFilters && clearFilters();
            setDataSource(await loadingData(""));
          }}
        >
          {resetText}
        </Button>
        <Button
          type="primary"
          size="small"
          // icon={<SearchOutlined />}
          onClick={() => {
            confirm();
          }}
        >
          {okText}
        </Button>
      </div>
    </div>
  );
};

export const getColumnSearchColumnProps = (_) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdownSearchColumn
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
    />
  ),
});

const FilterDropdownSearchColumn = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  return (
    <div style={{ padding: 0 }}>
      <div className="flex flex-col gap-2">
        <Input
          placeholder={searchTitle}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />

        <Divider style={{ padding: 0, margin: 0 }} />
        <div className="flex  gap-2 p-2 justify-between">
          <Button
            disabled={selectedKeys.length === 0}
            type="link"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              clearFilters && clearFilters();
            }}
          >
            {resetText}
          </Button>
          <Button
            type="primary"
            // icon={<SearchOutlined />}
            size="small"
            onClick={() => confirm()}
          >
            {okText}
          </Button>
        </div>
      </div>
    </div>
  );
};
