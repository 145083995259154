import create from "zustand";
export const OrderStore = create((set) => ({
  addOeder: true,
  setAddOeder: (addOeder) => set({ addOeder }),
  newadddO: true,
  setNewadddO: (newadddO) => set({ newadddO }),
  openOr: false,
  setOpenOr: (openOr) => set({ openOr }),
  record: null,
  setRecord: (record) => set({ record }),

  columnsData: [],
  setColumnsData: (columnsData) => set({ columnsData }),
  total: 1,
  setTotal: (total) => set({ total }),
  currentPage: 1,
  setCurrentPage: (currentPage) => set({ currentPage }),
  paginate: 25,
  setPaginate: (paginate) => set({ paginate }),

  dataSource: [],
  setDataSource: (dataSource) => set({ dataSource }),

  search: "",
  setSearch: (search) => set({ search }),
  fromHistory: null,
  setFromHistory: (fromHistory) => set({ fromHistory }),
}));
