import create from "zustand";
export const UserInfoStore = create((set) => ({
  addUserInfo: true,
  setAddUserInfo: (addUserInfo) => set({ addUserInfo }),

  newadddUs: true,
  setNewadddUs: (newadddUs) => set({ newadddUs }),
  
  open: false,
  setOpen: (open) => set({ open }),
  record: null,
  setRecord: (record) => set({ record }),

  columnsData: [],
  setColumnsData: (columnsData) => set({ columnsData }),
  total: 1,
  setTotal: (total) => set({ total }),
  currentPage: 1,
  setCurrentPage: (currentPage) => set({ currentPage }),
  paginate: 25,
  setPaginate: (paginate) => set({ paginate }),

  dataSource: [],
  setDataSource: (dataSource) => set({ dataSource }),

  search: "",
  setSearch: (search) => set({ search }),
  fromHistory: null,
  setFromHistory: (fromHistory) => set({ fromHistory }),
}));
