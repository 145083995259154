import { BASIC_URL } from "../index";

export const CreateOrUpdateCompany = async (info, id) => {
  //console.log(info);
  const formData = new FormData();
  formData.set("name", info.name);
  formData.set("files", info.files);

  try {
    var myHeaders = new Headers();
    myHeaders.append("token", sessionStorage.getItem("token"));

    // var raw = JSON.stringify(info);
    var requestOptions = {
      method: !id ? "POST" : "PUT",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    const response = !id
      ? await fetch(`${BASIC_URL}/api/company?id=${info.id}`, requestOptions)
      : await fetch(`${BASIC_URL}/api/company?id=${id}`, requestOptions);
    const data = await response.json();
    return {
      data: data,
      loading: true,
      hasErrors: false,
    };
  } catch (error) {
    console.log(error.message);
    return {
      data: {
        message: error.message,
      },
      loading: false,
      hasErrors: true,
    };
  }
};

export const fetchCompany = async ({ search }) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("token", sessionStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const response = await fetch(
      `${BASIC_URL}/api/company/showallPaginate?search=${search}`,
      requestOptions
    );
    if (!response || response.status >= 400) {
      return {
        data: {
          message: response,
        },
      };
    }
    const data = await response.json();
    return {
      data: data.details.docs,
      success: data.success,
    };
  } catch (error) {
    console.log(error.message);
  }
};

export const fetchCompanySelect = async () => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("token", sessionStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const response = await fetch(
      `${BASIC_URL}/api/company/showall`,
      requestOptions
    );
    if (!response || response.status >= 400) {
      return {
        data: {
          message: response,
        },
      };
    }
    const data = await response.json();
    return {
      data: data.details,
      success: data.success,
    };
  } catch (error) {
    console.log(error.message);
  }
};

export const DeleteCompanyById = async (id) => {
  //console.log(id);
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", sessionStorage.getItem("token"));

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    const response = await fetch(
      `${BASIC_URL}/api/company?id=${id}`,
      requestOptions
    );

    const data = await response.json();
    return {
      data: data,
    };
  } catch (error) {
    console.log(error.message);
    return {
      data: {
        message: error.message,
      },
    };
  }
};
