import TablePreview, { styeColumns } from "../../components/UI/TablePreview";
import { ProductsStore } from "../../store/ProductsStore";
import { IoIosSettings } from "react-icons/io";
import { MdPublic, MdPublicOff } from "react-icons/md";
import { Edit } from "./Edit";
import ProductImage from "./ProductImage";
import { useEffect, useState } from "react";
import { fetchProducts, UpdatePublic } from "../../api/ProductsApi/Index";
import { Avatar, Button, Popconfirm, Tooltip, message } from "antd";
import { FaTrash } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BASIC_URL } from "../../api";
import AddQty from "./AddQty";
import { Icon } from "@iconify/react";
function PTable() {
  const [DataSource, setDataSource] = useState([]);
  const [Isloading, setIsloading] = useState(false);
  const [Open, setOpen] = useState(false);
  const [OpenAddQty, setOpenAddQty] = useState(false);

  const {
    setImageL,
    total,
    setTotal,
    currentPage,
    setCurrentPage,
    paginate,
    setPaginate,
    columnsData,
    search,
    setAdd,
    setRecord,
    newadddQ,
  } = ProductsStore();

  const columns = [
    {
      className: styeColumns,
      title: "ت",
      dataIndex: "index",
      //   fixed: 'left',
      ellipsis: true,
      columnsData,
      width: 30,
      onFilter: (value, record) => record.name.indexOf(value) >= 0,
      sorter: (a, b) => {
        if (a === b) {
          return 0;
        } else if (a > b) {
          return -1;
        } else {
          return 1;
        }
      },
    },
    {
      className: styeColumns,
      title: " صور",
      dataIndex: "title",
      //   fixed: 'left',
      ellipsis: true,
      width: 30,
      render: (_, record) => (
        <Avatar
          onClick={() => {
            setImageL(record);
            setOpen(true);
          }}
          src={
            <img
              src={`${BASIC_URL}/api/productes/${record.id}/${record.url[0]}`}
              alt="avatar"
            />
          }
        />
      ),
    },
    {
      className: styeColumns,
      title: " اسم المنتج",
      dataIndex: "Pname",
      //   fixed: 'left',
      ellipsis: true,
      width: 100,
      sorter: (a, b) => {
        if (a === b) {
          return 0;
        } else if (a > b) {
          return -1;
        } else {
          return 1;
        }
      },
    },

    {
      className: styeColumns,
      title: " نوع المنتج",
      dataIndex: "category",
      ellipsis: true,
      width: 60,
      render: (_, record) => <span>{record.category.name}</span>,
    },

    {
      className: styeColumns,
      title: "الشركة المصنعة ",
      dataIndex: "company",
      ellipsis: true,
      width: 60,
      render: (_, record) => <span>{record.company.name}</span>,
    },

    {
      className: styeColumns,
      title: "الكمية",
      dataIndex: "qty",
      ellipsis: true,
      width: 40,
      //filters: variablesConst.status,
      //   onFilter: (value, record) => {
      //       return record.status === value
      //   },
      sorter: (a, b) => {
        if (a === b) {
          return 0;
        } else if (a > b) {
          return -1;
        } else {
          return 1;
        }
      },
      //   render: (text) => (
      //       <Badge color={text ? 'green' : 'red'} text={text ? activeTitle : inactiveTitle} />
      //   )
    },
    {
      className: styeColumns,
      title: "السعر",
      dataIndex: "price",
      ellipsis: true,
      width: 60,
      //filters: variablesConst.status,
      //   onFilter: (value, record) => {
      //       return record.status === value
      //   },
      sorter: (a, b) => {
        if (a === b) {
          return 0;
        } else if (a > b) {
          return -1;
        } else {
          return 1;
        }
      },
      //   render: (text) => (
      //       <Badge color={text ? 'green' : 'red'} text={text ? activeTitle : inactiveTitle} />
      //   )
    },
    {
      className: styeColumns,
      title: " التخفيض",
      dataIndex: "discount",
      ellipsis: true,
      width: 40,
      sorter: (a, b) => {
        if (a === b) {
          return 0;
        } else if (a > b) {
          return -1;
        } else {
          return 1;
        }
      },
    },
    {
      className: styeColumns,
      title: " وصف المنتج",
      dataIndex: "desc",
      ellipsis: true,
      width: 150,
      //filters: variablesConst.status,
      //   onFilter: (value, record) => {
      //       return record.status === value
      //   },
      sorter: (a, b) => {
        if (a === b) {
          return 0;
        } else if (a > b) {
          return -1;
        } else {
          return 1;
        }
      },
      render: (_, record) => {
        // Check if the product description is available and not empty or "undefined"
        if (record.desc !== "" && record.desc !== "undefined") {
          return <span>{record.desc}</span>;
        } else {
          return <span>لا يوجد وصف</span>;
        }
      },
      //   render: (text) => (
      //       <Badge color={text ? 'green' : 'red'} text={text ? activeTitle : inactiveTitle} />
      //   )
    },

    {
      className: styeColumns,
      title: (
        <div className="flex items-center justify-center">
          <IoIosSettings />
        </div>
      ),
      dataIndex: "settings",
      ellipsis: true,
      width: 32,
      fixed: "right",
      render: (_, record) => (
        <div className="flex items-center justify-center">
          <Edit record={record} />

          {/* <Tooltip placement="top" title="تعديل الكمية">
            <Popconfirm
              title="هل تريد التعديل ؟"
              okText="نعم "
              cancelText="كلا"
              onConfirm={() => {
                //handleOpenAddQty(record);
                setAdd(false);
                setRecord(record);
                setOpenAddQty(true);
              }}
            >
              <AiOutlinePlusCircle className="cursor-pointer mx-2" />
            </Popconfirm>
          </Tooltip> */}
          <Tooltip placement="top" title="تعديل الكمية">
            <Icon
              onClick={() => {
                setAdd(false);
                setRecord(record);
                setOpenAddQty(true);
              }}
              icon="icon-park:plus"
              className="mx-4  transition ease-in-out duration-200 transform hover:-translate-y-0 hover:scale-125"
            />
          </Tooltip>

          <Popconfirm
            className=""
            title={"هل أنت متأكد من تغيير الحالة "}
            placement="right"
            onConfirm={async () => {
              try {
                const data = await UpdatePublic(!record.isPopular, record.id);
                if (data.data.success === true) {
                  loadingData();
                  message.success("تمت عملية التعديل بنجاح");
                } else {
                  return message.error("حدث خطأ");
                }
              } catch (error) {
                return message.warning("يوجد خلل في الاتصال بالخادم");
              }
            }}
          >
            <span className=" text-sky-600 transition ease-in-out duration-200 transform hover:-translate-y-0 hover:scale-125">
              <Tooltip
                placement="topRight"
                title={
                  record.isPopular === false
                    ? "تغيير الحالة لشائع"
                    : "تغيير الحالة لغير شائع"
                }
              >
                {record.isPopular === false && <MdPublic />}
                {record.isPopular === true && (
                  <MdPublicOff style={{ color: "red" }} />
                )}
              </Tooltip>
            </span>
          </Popconfirm>
          {/* <span className="mr-2 text-red-700 transition ease-in-out duration-200 transform hover:-translate-y-0 hover:scale-125">
              <Tooltip placement="topRight" title={"الصور"}>
                <AiFillPicture onClick={()=>{
                  setImageL(record)
                  setOpen(true)

                  }} />
              </Tooltip>
            </span> */}
        </div>
      ),
    },
  ];
  useEffect(() => {
    let isStart = true;
    if (isStart) {
      setIsloading(false);
      loadingData();
    }
    return () => (isStart = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, paginate, search, newadddQ]);
  const loadingData = async () => {
    try {
      const data = await fetchProducts({
        page: currentPage,
        paginate: paginate,
        search: search,
      });
      setIsloading(true);
      //console.log(data.data);
      setDataSource(data.data);
      setTotal(data.total);
    } catch (error) {
      console.log("error.message");
    }
    setIsloading(true);
  };

  return (
    <>
      <TablePreview
        columns={columns}
        scrollX={true}
        dataSource={DataSource}
        loading={Isloading}
        total={total}
        paginate={paginate}
        currentPage={currentPage}
        setPaginate={setPaginate}
        setCurrentPage={setCurrentPage}
        // hasErrors={hasErrors}
        widthT={500}
      />
      <ProductImage visible={Open} setVisible={setOpen} />
      <AddQty visible={OpenAddQty} setVisible={setOpenAddQty} />
    </>
  );
}

export default PTable;
