import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { AuthStore } from '../../store/AuthStore/Index';
import { AppContainer } from "../UI/layout/appContainer";

// const useAuth = () => {
//   const { isAuth } = AuthStore();
//   return isAuth ;
// };
export const PrivateRoute = ({ children }) => {
  let location = useLocation();

  return (
    sessionStorage.getItem("isAuth") === "AdminIsCompletlyAuth" ?
    children ? (
      <AppContainer>{children}</AppContainer>
    ) : (
      <AppContainer>
        <Outlet />
      </AppContainer>
    )
      : <Navigate to="/auth" state={{ from: location }} />
  );
};
// export default useAuth;
