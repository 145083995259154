import React from "react";
 import arEG from "antd/lib/locale/ar_EG";
import "animate.css";
import "./index.css";
import { themeStore } from "./theme/useThemeStore";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider} from "antd";
import { App } from "./App";
import "./styles/main.less";
import { createRoot } from 'react-dom/client';
// import "./styles/tailwind.css"; 
const Index = () => {
  const { fontSize } = themeStore();
  // eslint-disable-next-line no-unused-expressions
  import(`./styles/fonts/fontSize${fontSize}.less`);
  return (
    <ConfigProvider
      direction="rtl"
      locale={arEG}
      csp={{ nonce: "YourNonceCode" }}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ConfigProvider>
  );
};

// render(<Index />, document.getElementById("root"));


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Index />);