import { Tooltip } from "antd";
import React from "react";
import { RiEdit2Fill } from "react-icons/ri";
import { createSearchParams, useNavigate } from "react-router-dom";

import { ProductsStore } from "../../store/ProductsStore";


export const Edit = ({ record}) => {
  const { setAdd, setRecord } = ProductsStore();
  const navigate = useNavigate();
  return (
    <>
      <span
        className="mr-2"
        onClick={() => {
          try {
            navigate({
              pathname: "/addproduct",
              search: `?${createSearchParams({ id: record["id"] })}`,
            });
            setRecord(record);
            setAdd(false);
          } catch (error) {
            console.log(error.message);
          }
        }}
      >
        <Tooltip placement="top" title={"تعديل"}>
          <RiEdit2Fill 
          className="  transition ease-in-out duration-200 transform hover:-translate-y-0 hover:scale-125" />
        </Tooltip>
      </span>
    </>
  );
};
