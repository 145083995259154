
import React from 'react'
export const CardItemColor = ({ Radio, value, itemColor, itemColor2, borderRadius1, borderRadius2 }) => {

    return (
        <Radio.Button
            value={value}
            style={{ backgroundColor: itemColor2 ,}}
        >
            <div
                style={{
                    height: '2.3rem',
                    width: '2.3rem',
                    borderRadius: borderRadius1
                }}
            >
                <div className='w-3'
                    style={{
                        backgroundColor: itemColor,
                        height: '2.3rem',
                        borderRadius: borderRadius2
                    }}
                />
            </div>

        </Radio.Button> 
    )
}
