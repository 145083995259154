import { Form, Input, Button, Upload, message } from "antd";
import { useState, useEffect } from "react";
import { CreateOrUpdateCompany } from "../../api/CompanyApi/Index";
import { LoadingSpin } from "../../components/UI/LoadingSpin";
import { ModelTabComponent } from "../../components/UI/modelTab/Index";
import { CompanyStore } from "../../store/Company";
import { v4 as uuidv4 } from "uuid";
import { BASIC_URL } from "../../api";
const AddNewCompany = ({ setVisible, visible, CompanAdd }) => {
  const [form] = Form.useForm();

  const { record, addCompany, newadddC, setNewadddC } = CompanyStore();
  const [loading, setLoading] = useState(false);
  //const [Cheklist, setCheklist] = useState([]);

  const handleCancel = () => {
    setVisible(false);
    //CompanAdd();
  };
  useEffect(() => {
    form.resetFields();
    if (addCompany === false) {
      initialValues();
    }
  }, [visible]);

  const handleOk = (e) => {
    form
      .validateFields()
      .then((val) => {
        onFinish(val);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const data = await CreateOrUpdateCompany(
        {
          id: uuidv4(),
          name: values.name,
          files: form.getFieldValue("files"),
        },
        record ? record.id : ""
      );
      setNewadddC(!newadddC);
      form.resetFields();
      message.success(
        record ? "تمت عملية التعديل بنجاح" : "تمت عملية الإضافة بنجاح"
      );
       handleCancel();
      //setVisible(false);
    } catch (error) {
      message.error(" لم يتم الاضافة ");
    } finally {
      setLoading(false);
    }
  };

  const initialValues = () => {
    form.setFieldsValue({
      name: record.name,
    });
  };
  const AddCompany = () => {
    useEffect(() => {
      if (!addCompany) {
        fetch(`${BASIC_URL}/api/companies/${record.id}/${record.ImageUrl}`)
          .then((res) => res.blob())
          .then((blob) => {
            const aa = new File([blob], `${record.ImageUrl}`, {
              type: blob.type,
            });
            setFileList([
              {
                uid: aa.uid,
                name: aa.name,
                status: "done",
                url: `${BASIC_URL}/api/companies/${record.id}/${record.ImageUrl}`,
              },
            ]);
            form.setFieldsValue({
              files: aa,
            });
          });
      }
    }, []);
    const [fileList, setFileList] = useState([]);

    const onChange = ({ fileList: newFileList }) => {
      setFileList(newFileList);
      form.setFieldsValue({
        files: newFileList[0].originFileObj,
      });
      console.log("saaa", form.getFieldValue("image"));
    };
    // const onPreview = async (file) => {
    //   let src = file.url;
    //   if (!src) {
    //     src = await new Promise((resolve) => {
    //       const reader = new FileReader();
    //       reader.readAsDataURL(file.originFileObj);
    //       reader.onload = () => resolve(reader.result);
    //     });
    //   }
    //   const image = new Image();
    //   image.src = src;
    //   const imgWindow = window.open(src);
    //   imgWindow?.document.write(image.outerHTML);
    // };

    return (
      <Form onFinish={onFinish} form={form} layout="horizontal">
        <div className="lg:block sm:block md:block xl:block pr-5 ml-5 items-center">
          <Form.Item
            className="lg:w-full  xl:w-full     animate__animated animate__fadeInLeft"
            name="name"
            label="اسم الشركة"
          >
            <Input
              autoComplete="off"
              placeholder="ادخل اسم الشركة "
              allowClear={true}
            />
          </Form.Item>
          <Form.Item
            className="lg:w-full  xl:w-full animate__animated animate__fadeInLeft"
            name="files"
            label=" صورة المنتج "
            rules={[{ required: true, message: "   يجب اختيار صورة المنتج" }]}
          >
            {/* <ImgCrop rotationSlider> */}
            <Upload
              maxCount={1}
              multiple={false}
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              showUploadList={{
                showRemoveIcon: true,
                showPreviewIcon: false,
                showDownloadIcon: false,
              }}
              beforeUpload={() => false} // Disable default upload behavior
              //  autoUpload={false} // Delay the upload
            >
              {fileList.length < 1 && "اضافة صورة"}
            </Upload>
          </Form.Item>
        </div>
      </Form>
    );
  };
  return (
    <div>
      <ModelTabComponent
        visible={visible}
        setVisible={setVisible}
        title={addCompany ? "اضافة الشركة" : "تعديل اسم الشركة"}
        content={<AddCompany />}
        handleCancel={handleCancel}
        loading={loading}
        handleOk={handleOk}
      />
    </div>
  );
};

export default AddNewCompany;
