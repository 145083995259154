import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./components/routes/privateRoute";
import { RouteProtection } from "./components/routes/routeProtection";
import { LoadingSpin } from "./components/UI/LoadingSpin";
import Products from "./pages/Products/Products";
import Auth from "./pages/Auth/Auth";
import AddOrEditProduct from "./pages/Products/AddOrEditProduct";
import Category from "./pages/category";
import CompanyMade from "./pages/CompanyMade";
import InventoryTable from "./pages/RequestPage/Inventory";
import UserInfo from "./pages/UserInfo";
import OrderState from "./pages/OrderState/Index";
const ErrorPage = lazy(() => import("./pages/errorPage"));

export const App = () => {
  const [roul, setRoul] = useState(null);
  useEffect(() => {
    if (sessionStorage.getItem("UserRoll") === "admin") {
      setRoul(true);
    } else {
      setRoul(false);
    }
  }, [setRoul]);
  return (
    <RouteProtection>
      <Routes>
        <Route path="/auth" element={<Auth />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Products />} />
          <Route path="/addproduct" element={<AddOrEditProduct />} />
          <Route path="/category" element={<Category />} />
          <Route path="/CompanyMade" element={<CompanyMade />} />
          <Route path="/orderstate" element={<OrderState />} />
          <Route path="/Inventory" element={<InventoryTable />} />
          <Route path="/userPage" element={<UserInfo />} />
          {/* {roul ? (
            <Route path="/userPage" element={<UserInfo />} />
          ) : (
            <Route
              path="*"
              element={
                <Suspense fallback={<LoadingSpin />}>
                  <ErrorPage />
                </Suspense>
              }
            />
          )} */}
        </Route>
        <Route
          path="*"
          element={
            <Suspense fallback={<LoadingSpin />}>
              <ErrorPage />
            </Suspense>
          }
        />
      </Routes>
    </RouteProtection>
  );
};
