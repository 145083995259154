import { Layout, Spin } from 'antd'
import React from 'react'

export const LoadingSpin = () => {
    return (
        <Spin size="large">
          <Layout className="app" />
        </Spin>
      )
}
