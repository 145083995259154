import { BASIC_URL } from "../index";

export const fetchCarts = async ({ page, paginate, search, start, end }) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("token", sessionStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const response = await fetch(
      `${BASIC_URL}/api/cart/showall?search=${search}&page=${page}&paginate=${paginate}&fromDate=${start}&toDate=${end}`,
      requestOptions
    );
    if (!response || response.status >= 400) {
      return {
        data: {
          message: response,
        },
      };
    }
    const data = await response.json();
    if (data.success === false) {
      return {
        data: [],
      };
    }
    let list = [];
    data.details.docs.map((item, index) => [
      list.push({
        key: (page - 1) * paginate + index + 1,
        index: (page - 1) * paginate + index + 1,
        id: item.id,
        OrderState: item.OrderState ? item.OrderState.name : "", 
        fullName: item.User ? item.User.fullName : "", 
        phoneNumber: item.User ? item.User.phoneNumber : "",
        createdAt: item.createdAt ? item.createdAt.split("T")[0] : "",
        MidCarts: item.MidCarts,
      }),
    ]);
    return {
      data: list,
      total: data.details.total,
    };
  } catch (error) {
    console.log(error.message);
  }
};

export const createOrUpdateCarts = async (info, id) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", sessionStorage.getItem("token"));

    var raw = JSON.stringify(info);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // var myHeaders = new Headers();
    // myHeaders.append("token", sessionStorage.getItem("token"));
    // console.log(info );
    // var raw = JSON.stringify(info);
    // var requestOptions = {
    //   method: "PUT",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };
    // const url = `${BASIC_URL}/api/cart?id=${id}`;
    // const response = await fetch(url, requestOptions);
    const response = await fetch(
      `${BASIC_URL}/api/cart/?id=${id}`,
      requestOptions
    );

    const data = await response.json();
    return {
      data: data,
    };
  } catch (error) {
    console.log(error.message);
    return {
      data: {
        message: error.message,
      },
    };
  }
};
