import React, { useEffect } from "react";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import PTable from "../../components/ProductsTable/PTable";
import { ProductsStore } from "../../store/ProductsStore";
import { Link } from "react-router-dom";
const Products = () => {
  const { search, setSearch, setRecord, setAdd, setCurrentPage } =
    ProductsStore();

  useEffect(() => {
    let isStart = true;
    if (isStart) {
      setSearch("");
    }
    return () => {
      isStart = false;
    };
  }, [setSearch]);
  return (
    <>
      <div className="flex items-center justify-between h-10">
        <Input
          allowClear
          suffix={<SearchOutlined style={{ color: "#808080" }} />}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setCurrentPage(1);
          }}
          placeholder={"بحث"}
          style={{ width: 250 }}
        />
        <div className="flex justify-evenly items-center gap-2">
          <Link to={"/addproduct"}>
            <Button
              style={{ display: "flex" }}
              icon={<PlusOutlined />}
              onClick={() => {
                setAdd(true);
                setRecord(null);
              }}
              className="mr-2 flex items-center transition ease-in-out duration-200 transform hover:-translate-y-0 hover:scale-105"
            >
              اضافة
            </Button>
          </Link>
        </div>
      </div>
      <PTable />
    </>
  );
};

export default Products;
