import React, {  useState } from "react";

import { ModelTabComponent } from "../../components/UI/ModelComponent/Index";
import { ProductsStore } from "../../store/ProductsStore/index";
import { BASIC_URL } from "../../api";
function ProductImage({ setVisible, visible }) {
  const {ImageL } = ProductsStore();
  const [confirmLoading, setconfirmLoading] = useState(false);

  const handleCancel = () => {
    setVisible(false);
  };

  const Image = () => {
    if(!ImageL)return(<div></div>)
    return (
      
      <div>
        {
          ImageL.url.map((item)=>(
            <img
            alt="/"
            src={`${BASIC_URL}/api/productes/${ImageL.id}/${item}`}
            className="mb-4"
            onClick={()=>window.open(`${BASIC_URL}/productes/${ImageL.id}/${item}`, '_blank')}
          ></img>
          ))
        }
      </div>
    );
  };
  return (
    <div>
      <ModelTabComponent
        visible={visible}
        setVisible={setVisible}
        title={"صور المنتج"}
        content={<Image />}
        handleCancel={handleCancel}
        // handleOk={handleOk}
        confirmLoading={confirmLoading}
      />
    </div>
  );
}

export default ProductImage;
